import {React, useState, useEffect, useRef} from 'react'
import Draggable from "react-draggable";

import { DeviceDetail } from '../../../recoil/atom/deviceDetail';

function WebContent({ selectedLink, clearLink }) {

    const [currentURL, setCurrentURL] = useState(selectedLink);
    const [htmlContent, setHtmlContent] = useState('');
    const htmlRef = useRef(null);

    useEffect(() => {
        fetch(`https://futurist.io/fetch?url=${currentURL}`, {
            method: 'GET'
        })
        .then(response => response.text())
        .then(html => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
            const fetchedBody = doc.body;
            console.log(fetchedBody);
            const background = fetchedBody.getAttribute('background');
            if (background) {
                const containerDiv = document.createElement('div');
                containerDiv.style.backgroundImage = `url(${background})`;

                containerDiv.innerHTML = fetchedBody.innerHTML;
                console.log(containerDiv.outerHTML);
                setHtmlContent(containerDiv.outerHTML);
            } else {
                setHtmlContent(fetchedBody.innerHTML);
            }
        })
        .catch(error => {
            console.error("Bad error fetching", error);
        });
    }, [currentURL]);

    useEffect(() => {
        const handleClick = (event) => {
            if (event.target.tagName === 'A' || event.target.tagName === 'BUTTON') {
                event.preventDefault();
                const href = event.target.getAttribute('href');
                setCurrentURL(href);
            }
        };

        const container = htmlRef.current;
        container.addEventListener('click', handleClick);

        return () => {
            container.removeEventListener('click', handleClick);
        };
    }, [htmlContent]);

    const device = DeviceDetail();
    let xplorer_height = null;

    if(device.width > 850) {
        xplorer_height = "50vh";
    } else {
        xplorer_height = "80vh";
    }

    return (
        <div ref={htmlRef} dangerouslySetInnerHTML={{__html: htmlContent}} style={{color: "#6bf178"}} />  
    )
}

export default WebContent
