import React from 'react'

function Content({ content }) {
    const { paragraphs } = content;
    return (
        <div className="modal-window-container" style={{maxHeight: "20em", overflowY: "scroll"}}>
            {
                paragraphs.map((p, i) => (
                    <>
                        <span key={i}>{p}</span> <br />
                    </>
                ))
            }

        </div>
    )
}

export default Content
