import React, { useState, useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { trainingSelector, isCapturingSelector } from '../../../../recoil/atom/modelDetailSelector';
import { headsetStreamSelector } from '../../../../recoil/atom/headsetDetailSelector';

function EyeBlinks({
    headsetClient
}) {
    const [trainingType, setTrainingType] = useState("open");
    const [captureStart, setCaptureStart] = useState(null);
    const [trainingSample, setTrainingSample] = useRecoilState(trainingSelector);

    const headsetStream = useRecoilValue(headsetStreamSelector);
    const headsetStreamRef = useRef(headsetStream);

    const [isCapturing, setIsCapturing] = useRecoilState(isCapturingSelector);

    useEffect(() => {
        headsetStreamRef.current = headsetStream;
    }, [headsetStream]);

    useEffect(() => {
        let intervalId;

        if(isCapturing) {

            console.log("Start time is:", Math.floor(Date.now()) / 1000);
            headsetClient.injectMarker("Starting Training Capture");
            intervalId = setInterval(() => {
                if (Math.floor(Date.now() / 1000 - (headsetStreamRef.current[captureStart].info.startTime / 1000)) > 10) {
                    console.log("Completed 10 second run");
                    
                    setIsCapturing(false);
                    clearInterval(intervalId);
                    const newSamples = headsetStreamRef.current.slice(captureStart, headsetStreamRef.current.length - 1);
                    
                    extractSample(newSamples, trainingType, setTrainingSample);
                    headsetClient.injectMarker("Ending Training Capture");

                    setCaptureStart(null);
                } else {
                    console.log("Capturing.. ", Math.floor(Date.now()) / 1000 - (headsetStream[captureStart].info.startTime / 1000))
                }
            }, 1000);
    
        }

        return () => {
            clearInterval(intervalId);
        }
    }, [isCapturing]);

    function extractSample(eegSample, trainingType, setTrainingSample) {
        console.log("Now extracting the sample");
        let eegState = 0;
        if (trainingType === "open") {
            eegState = 1;
        }
        const newSamples = eegSample.map(sample => ({
            timestamp: sample.info.startTime,
            eegReadings: sample.psd,
            state: eegState
        }));

        setTrainingSample(newSamples);
    }


    function changeScreen(e) {
        console.log(e);
        setTrainingType(e.target.value)
    }

    function toggleCapture() {
        if(!captureStart){
            setCaptureStart(headsetStream.length - 1);
        }
        setIsCapturing(true);
    }

    return (
        <>
            {
                trainingType === "open" &&
                <>
                    <p>OPEN EYE TRAINING</p>
                    <hr style={{ border: ".15em dashed" }} />
                    <p>Capture EEG data with eyes open for 10 seconds.</p>
                    <div style={{ display: "block", margin: ".5em" }}>
                        {
                            headsetClient ? (
                                <>
                                    {
                                        headsetStream ? (
                                            <>
                                                {
                                                    isCapturing ? (
                                                        <>
                                                            <button className="button" onClick={toggleCapture}>Pause Capture</button>

                                                        </>
                                                    )
                                                        :
                                                        <>
                                                            <button className="button" onClick={toggleCapture}>Start Capture</button>
                                                        </>
                                                }
                                                
                                                {
                                                    isCapturing &&
                                                    <>
                                                        <p>Capturing ..</p>
                                                    </>
                                                }
                                            </>
                                        )
                                            :
                                            <>
                                                <p>Headset not streaming!</p>
                                            </>
                                    }

                                </>
                            )
                                :
                                <>
                                    <p>No headset connected!</p>
                                </>
                        }

                    </div>
                    <button className="button-inverted" value="closed" style={{ width: "100%" }} onClick={changeScreen}>Next Step -&gt;</button>
                </>
            }
            {
                trainingType === "closed" &&
                <>
                    <p>CLOSED EYE TRAINING</p>
                    <hr style={{ border: ".15em dashed" }} />
                    <p>Capture EEG data with eyes closed for 10 seconds.</p>
                    <div style={{ display: "block", margin: ".5em" }}>
                        {
                            headsetClient ? (
                                <>
                                    {
                                        headsetStream ? (
                                            <>
                                                {
                                                    isCapturing ? (
                                                        <>
                                                            <button className="button" onClick={toggleCapture}>Pause Capture</button>

                                                        </>
                                                    )
                                                        :
                                                        <>
                                                            <button className="button" onClick={toggleCapture}>Start Capture</button>
                                                        </>
                                                }
                                                
                                                {
                                                    isCapturing &&
                                                    <>
                                                        <p>Capturing ..</p>
                                                    </>
                                                }
                                            </>
                                        )
                                            :
                                            <>
                                                <p>Headset not streaming!</p>
                                            </>
                                    }

                                </>
                            )
                                :
                                <>
                                    <p>No headset connected!</p>
                                </>
                        }

                    </div>
                    <div>
                        <button className="button-inverted" value="open" style={{ width: "100%" }} onClick={changeScreen}>&lt;- Back Step</button>

                    </div>
                </>
            }
        </>
    )
}
export default EyeBlinks
