import React, { useEffect, useState, useContext } from 'react'
import DataContext from '../../../contexts/dataContext'
import Content from './Content'

function ContentFactory({ id, isMobile, bringToFront }) {
    const data = useContext(DataContext);
    const [item, setItem] = useState(null);

    useEffect(() => {
        const file = data.getItem(id);
        setItem(file);
    }, [id, data]);

    if (item === null) {
        return (<div></div>);
    }

    switch (item.id) {
        default:
            return (
                <div 
                    className="modal-window-background"
                    onClick={bringToFront}
                    id={id}
                    >
                    <Content content={item.content} />
                </div>
            );
    }

}

export default ContentFactory
