import { atom, selector } from 'recoil';

export const deviceDetailState = atom({
    key: 'deviceDetailState',
    default: {
        width: 0,
        height: 0,
        type: '',
        appWidth: '',
        deskSpace: {
            width: 0,
            height: 0,
        },
        windows: [
        ]
    }
});

export const desktopResizing = selector({
    key: 'desktopResizing',
    get: ({get}) => get(deviceDetailState),
    set: ({set}, sizing) => {
        set(deviceDetailState, (prev) => {
            return {...prev, deskSpace: sizing}
        });
    }
});

export const windowManipulator = selector({
    key: 'windowManipulator',
    get: ({get}) => get(deviceDetailState),
    set: ({set}, {type, window}) => {
        set(deviceDetailState, (prev) => {
            let newWindows = [...prev.windows];
            switch (type) {
                case 'add':
                    newWindows.push(window);
                    break;
                case 'remove':
                    newWindows = newWindows.filter(w => w.id !== window.id);
                    break;
                case 'update':
                    newWindows = newWindows.map(w => w.id === window.id ? {...w, ...window.props} : w);
                    break;
                case 'reindex':
                    const maxZIndex = 99999;
                    const windowIndex = newWindows.findIndex(w => w.id === window.id);
                    if (windowIndex > -1) {
                        const updatedWindow = {...newWindows[windowIndex], zIndex: maxZIndex};
                        newWindows[windowIndex] = updatedWindow;
                        newWindows = newWindows.map((w, index) => {
                            if (index !== windowIndex) {
                                return {...w, zIndex: maxZIndex - (index + 1)};
                            }
                            return w;
                        });
                    }
                    break;
                default:
                    break;
            }
            return { ...prev, windows: newWindows };
        });
    }
});