import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { modelDetailState } from '../../../recoil/atom/modelDetailState';
import { headsetDetailState } from '../../../recoil/atom/headsetDetailState';
import { trainingSelector, isTrainingSelector, trainingStatusSelector } from '../../../recoil/atom/modelDetailSelector';

import TrainingStatus from './training/TrainingStatus';
const worker = new Worker(new URL('./training/TrainingWorkerv2.js', import.meta.url));


function BrainData({ setScreen, headsetClient, headsetDataStream }) {

    const [trainingStart, setTrainingStart] = useState(0);
    const [trainingSample, setTrainingSample] = useRecoilState(trainingSelector);
    const [isTraining, setIsTraining] = useRecoilState(isTrainingSelector);
    const [trainingStatus, setTrainingStatus] = useRecoilState(trainingStatusSelector);
    const [headsetDetail, setHeadsetDetail] = useRecoilState(headsetDetailState)
    
    const [selectedStatus, setSelectedStatus] = useState("manage_trainings");

    useEffect(() => {
        let intervalId;
        
        if (isTraining) {
            const handleWorkerMessage = (event) => {
                if (event.data.training) {
                    console.log('Training Accuracy', event.data.training)
                    setTrainingStatus(-1);
                    setIsTraining(false);
                    //worker.terminate();
                }
                if (event.data.step) {
                    setTrainingStatus(event.data.step);
                }
                console.log('Worker Message:', event.data)
            };
    
            worker.addEventListener('message', handleWorkerMessage);
    
            console.log("Staring training..")
            console.log("The data is", trainingSample)
            worker.postMessage({
                command: 'startProcessing',
                data: trainingSample
            });    
        }
    
        return () => {
            clearInterval(intervalId);
            //worker.removeEventListener('message', handleWorkerMessage);
            //worker.terminate();
        }
    }, [trainingStart, isTraining]);

    function changeScreen(e) {
        setSelectedStatus(e.target.value)
    }

    function toggleTraining() {
        setIsTraining(true);
    }

    function toggleExport(){
        if(trainingSample.length > 0){
            // Export training samples
            const timestamp = Math.floor(Date.now() / 1000);    

            const dataString = JSON.stringify(trainingSample);
            const blob = new Blob([dataString], {type: 'application/json'});
            const downloadLink = URL.createObjectURL(blob);
            
            const extractDownload = document.createElement('a');
            extractDownload.href = downloadLink;
            extractDownload.download = `eeg_${timestamp}_${trainingSample.length}.json`;
            extractDownload.style.display = 'none';
            
            document.body.appendChild(extractDownload);
            extractDownload.click();
            
            document.body.removeChild(extractDownload);
            URL.revokeObjectURL(downloadLink);
        }
    }

    return (
        <>
            <div
                style={{display: "inline-grid", width: "100%"}}>
                <select
                    style={{
                        display: "block",
                        margin: "auto",
                        backgroundColor: "#02111B",
                        color: "#6bf178",
                        position: "relative",
                        border: "outset #6BF178 medium",
                        fontSize: "1em",
                        padding: ".5em",
                        outline: "solid .1em #02111B"
                    }}
                    onChange={changeScreen}
                >
                    <option value="manage_trainings">Model Training</option>
                    <option value="export_training">Export Training Data</option>
                    <option value="training_history">Training History</option>
                </select>
            </div>
            <div 
                style={{ 
                    backgroundColor: "#02111b", 
                    margin: ".5em 0.25em 0.25em", 
                    padding: ".25em", 
                    border: ".25em inset #6bf178" }}>

                <div
                    style={{backgroundColor: "rebeccapurple", padding: ".5em"}}>

                    {
                        selectedStatus === "manage_trainings" &&
                        <>
                            <TrainingStatus />
                            {
                                headsetDetail.isStreaming ? (
                                    <>
                                        <button style={{display: "block", width: "100%"}} className="button" onClick={toggleTraining}>Run Training</button>
                                    </>
                                )
                                :
                                <>
                                     <button style={{display: "block", width: "100%", backgroundColor: "lightgrey", color: "red", border: "outset red .25em"}} className="button" >No Headset Connected</button>
                                </>
                            }
                        </>
                    }
                    {
                        selectedStatus === "export_training" &&
                        <>
                            <p>Exports captured content from trainings with their respective labels.</p>
                            <p style={{backgroundColor: "#6BF178", color: "#02111B", padding: ".5em"}}>Included sample size: {trainingSample.length}</p>
                            <div style={{display: "flex"}}>
                                <button style={{width: "100%" }}className="button" onClick={toggleExport}>Export as JSON</button>
                            </div>
                        </>
                    }
                    {
                        selectedStatus === "training_history" &&
                        <>
                            <p style={{padding: ".5em", backgroundColor: "blue", color: "white"}}>CAPTURED SUMMARY</p>
                            <table>
                                <tr>
                                    <td>
                                        Eyes Closed:
                                    </td>
                                    <td>
                                        {trainingSample.length}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Eyes Open:
                                    </td>
                                    <td>
                                        {trainingSample.length}
                                    </td>
                                </tr>
                                <tr style={{backgroundColor: "#6bf178", color: "#02111B"}}>
                                    <td>
                                        Total Samples:
                                    </td>
                                    <td>
                                        {headsetDetail.headsetStream.length}
                                    </td>
                                </tr>
                            </table>
                            <p style={{padding: ".5em", backgroundColor: "blue", color: "white"}}>TRAINING SUMMARY</p>
                            <table>
                                <tr>
                                    <td>
                                        Total Epochs:
                                    </td>
                                    <td>
                                        {trainingSample.length}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Model Accuracy:
                                    </td>
                                    <td>
                                        {trainingSample.length}
                                    </td>
                                </tr>
                            </table>
                        </>
                    }
                </div>
            </div>
        </>
    )
}
export default BrainData
