import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { deviceDetailState } from "./deviceDetailState";

export const DeviceDetail = () => {
    const [deviceDetails, setDeviceDetails] = useRecoilState(deviceDetailState)

    useEffect(() => {
        if(typeof window !== 'undefined') {
            function handleResize() {
                const width = window.innerWidth;
                const height = window.innerHeight;
                const type = width < 850 ? 'mobile' : 'desktop';

                // Create better responsive windows
                let appWidth = '';
                if (width >= 1920) {
                    appWidth = '50em';
                } else if (width < 1920 && width > 1000) {
                    appWidth = '40em';
                } else if (width < 1000 && width > 640) {
                    appWidth = '30em';
                } else {
                    appWidth = `${width * .9}px`;
                }

                const updatedDetails = {
                    ...deviceDetails,
                    width,
                    height,
                    type,
                    appWidth
                }
                setDeviceDetails(updatedDetails);
            }

            window.addEventListener('resize', handleResize);
            handleResize();

            return () => window.removeEventListener('resize', handleResize);
        }
    }, [setDeviceDetails]);

    return deviceDetails

}