import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { sessionDetailState } from '../../../recoil/atom/sessionDetailState';
import { profileDetailState} from '../../../recoil/atom/profileDetailState';

function CreateSession({ selectedScreen }) {

    const [profileDetails, setProfileDetails] = useRecoilState(profileDetailState);
    const [sessionDetails, setSessionDetails] = useRecoilState(sessionDetailState);
    const [selected, setSelected] = useState(null);
    const [captureTime, setCaptureTime] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    const handleChange = (event) => {
        setSelected(event.target.value);
    }

    /*
     Needs to:
     - Update session name
     - Add session description
     - Identify start time when capture starts
     - Extend session to unix start + length
      
    */

     const handleInputChange = (sub, key, value) => {
        setSessionDetails(prevDetails => ({
            ...prevDetails,
            newSession: {
                ...prevDetails.newSession,
                [sub]: { ...prevDetails.newSession[sub], [key]: value}
            }
        }));
    };

    const updateTimeCapture = (sub, key, value) => {
        setSessionDetails(prevDetails => ({
            ...prevDetails,
            newSession: {
                ...prevDetails.newSession,
                [sub]: { ...prevDetails.newSession[sub], [key]: parseInt(value)}
            }
        }));
    };

    const createOptions = (num) => {
        return [...Array(num).keys()].map(i => <option key={i} value={i}>{i}</option>)
    }

    return (
        <>
            {
                selectedScreen === "Details" &&
                <>
                    <h4
                        style={{
                            margin: "auto",
                            textAlign: "center",
                            backgroundColor: "#02111B",
                            color: "#6BF178",
                            marginBottom: ".5em",
                            width: "75%",
                            padding: ".5em",
                            marginTop: "-2.25em",
                            border: "double .25em",
                            lineHeight: "1.2"
                        }}
                    >
                        Session Details
                    </h4>
                    <p>
                        Provide some details about the session you'd like to capture.
                    </p>
                    <div style={{margin: ".5em"}}>
                        <input placeholder="Enter a name" 
                            value={sessionDetails.newSession.meta.name}
                            onChange={e => handleInputChange('meta', 'name', e.target.value)}
                            style={{
                                backgroundColor: "#02111b",
                                color: "#6bf178",
                                border: "#6bf178 .25em ridge",
                                fontFamily: "Print Char",
                                padding: ".5em",
                                width: "90%",
                                margin: "auto"
                            }} />
                    </div>
                    <div style={{margin: ".5em"}}>
                        <textarea placeholder="Enter a description" 
                            value={sessionDetails.newSession.meta.description}
                            onChange={e => handleInputChange('meta', 'description', e.target.value)}
                            style={{
                                backgroundColor: "#02111b",
                                color: "#6bf178",
                                border: "#6bf178 .25em ridge",
                                fontFamily: "Print Char",
                                padding: ".5em",
                                width: "90%",
                                margin: "auto",
                                minHeight: "5em"
                            }} />
                    </div>
                </>
            }
            {
                selectedScreen === "Start" &&
                <>
                    <h4
                        style={{
                            margin: "auto",
                            textAlign: "center",
                            backgroundColor: "#02111B",
                            color: "#6BF178",
                            marginBottom: ".5em",
                            width: "75%",
                            padding: ".5em",
                            marginTop: "-2.25em",
                            border: "double .25em",
                            lineHeight: "1.2"
                        }}
                    >
                        Profile Selection
                    </h4>
                    <p>
                        Profiles are used to connect capture sessions with a specific id.
                    </p>
                    <select 
                        style={{backgroundColor: "#02111B", color: "#6BF178", marginBottom: ".25em", border: "#6bf178 outset .25em", outline: "solid .1em #02111B", fontSize: "1em", padding: ".5em"}}
                        value={selected}
                        onChange={handleChange}
                        >
                        <option value="">Select A Profile</option>
                        {
                            profileDetails.profiles.map((profile, index)=> (
                                <option key={index} value={index}>{profile.id}</option>
                            ))
                        }
                    </select>
                </>
            }
            {
                selectedScreen === "Start" &&
                <>
                    <h4
                        style={{
                            margin: "auto",
                            textAlign: "center",
                            backgroundColor: "#02111B",
                            color: "#6BF178",
                            marginBottom: ".5em",
                            width: "75%",
                            padding: ".5em",
                            marginTop: "-2.25em",
                            border: "double .25em",
                            lineHeight: "1.2"
                        }}
                    >
                        Session Length
                    </h4>
                    <p>
                        Select the length of time for the session.
                    </p>
                    <div style={{display: "inline-flex"}}>
                        <div style={{padding: ".5em", display: "grid"}}>
                            <select style={{ marginBottom: ".5em" }} onChange={(e) => updateTimeCapture('meta', 'hours', e.target.value)}>
                                {createOptions(24)}
                            </select>
                            <span>Hours</span>
                        </div>
                        <div style={{padding: ".5em", display: "grid"}}>
                            <select style={{ marginBottom: ".5em" }} onChange={(e) => updateTimeCapture('meta', 'minutes', e.target.value)}>
                                {createOptions(60)}
                            </select>
                            <span>Minutes</span>
                        </div>
                        <div style={{padding: ".5em", display: "grid"}}>
                            <select style={{ marginBottom: ".5em" }} onChange={(e) => updateTimeCapture('meta', 'seconds', e.target.value)}>
                                {createOptions(60)}
                            </select>
                            <span>Seconds</span>
                        </div>
                    </div> 
                </>
            }
        </>
    )
}
export default CreateSession
