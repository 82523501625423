import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { DeviceDetail } from '../recoil/atom/deviceDetail';
//import CircularHeatmap from './sidebar_files/ChannelHeatmap';
//import MultiPlotContours from './sidebar_files/ChannelHeatmap';
//import Heatmap from './sidebar_files/ChannelHeatmap';
import BrainWaveChart from './sidebar_files/BrainWaveChart';
import TrainingChecklist from './sidebar_files/ConnectionStatus';
import { headsetDetailState } from '../recoil/atom/headsetDetailState';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceDetailState } from '../recoil/atom/deviceDetailState';

const fadeInOut = keyframes`
  100%, 100% { opacity: .1; }
`;

const Char = styled.span`
  animation: ${fadeInOut} 3s;
  animation-delay: ${({ delay }) => delay}s;
`;

const FadeText = ({ text, onComplete }) => {
  const [completed, setCompleted] = useState(false);

  const handleAnimationEnd = () => {
    setCompleted(true);
    if (onComplete) {
      setTimeout(onComplete, 1000); // Delay onComplete by 1 second
    }
  };

  return (
    <div>
      {text.split('').map((char, index) => (
        <Char key={index} delay={index * 0.1} onAnimationEnd={index === text.length - 1 ? handleAnimationEnd : null}>
          {char}
        </Char>
      ))}
      {completed && <p>╰⊱⭐⊱╮꧁ Loading Complete ꧂╭⊱⭐≺</p>}
    </div>
  );
};

function Sidebar({
  menuClick,
  toggleAuth,
  menuEnabled,
  headsetDataStream,
}) {
  const device = DeviceDetail();
  const deviceDetails = useRecoilValue(deviceDetailState);
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const [headsetDetail, setHeadsetDetail] = useRecoilState(headsetDetailState);

  const [selectedTab, setSelectedTab] = useState("Status");

  const handleAnimationComplete = () => {
    console.log('Delayed animation complete action');
    setAnimationCompleted(true);
  };

  function tabSelection(e) {
    setSelectedTab(e.target.textContent);
  }

  return (
    <div
      style={{
        width: device.width > 850 ? '35vw' : '90%',
        zIndex: '99999',
        right: '.5em',
        height: '94.5vh',
        position: 'absolute',
        top: '0',
        border: 'solid .1em #6BF178',
        backgroundColor: 'rgba(0,0,0,.85)',
        marginTop: '2.6em',
      }}
    >
      <div
        className="sidebar-inner"
        style={{
          height: '99%',
          border: 'solid .1em',
          margin: '.25em'
        }}
      >
        <div
          style={{
            padding: '.25em',
            textAlign: 'center',
            fontWeight: 800,
          }}
        >
          <div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "50% 50%"
                }}>
                  <button className={selectedTab === "Status" ? 'tab-selected' : 'tab'} onClick={tabSelection} style={{
                    fontFamily: "Print Char",
                    padding: ".25em",
                  }}>Status</button>
                  <button className={selectedTab === "Changelog" ? 'tab-selected' : 'tab'} onClick={tabSelection} style={{
                    fontFamily: "Print Char",
                    padding: ".25em",
                    border: "none"
                  }}>Changelog</button>
                </div>
                {
                  selectedTab === 'Status' &&
                  <>
                    <p style={{padding: ".5em", backgroundColor: "#6bf178", color: "#02111B"}}>Connection Status</p>
                    <TrainingChecklist /> 
                  </>
                }
                {
                  selectedTab === 'Changelog' &&
                  <>
                    <p style={{padding: ".5em", backgroundColor: "blue", color: "white"}}>July 28, 2024 // v.8.1</p>
                    <div style={{textAlign: "left"}}>
                      <p>- Implemented separate profiles for data capture</p>
                    </div>
                    <p style={{padding: ".5em", backgroundColor: "blue", color: "white"}}>July 17, 2024 // v.8</p>
                    <div style={{textAlign: "left"}}>
                      <p>- Implementation of window global state operators</p>
                      <p>- Experimental window resizing feature</p>
                      <p>- Preparation for window component library</p>
                    </div>
                    <p style={{padding: ".5em", backgroundColor: "blue", color: "white"}}>June 20, 2024 // v.7</p>
                    <div style={{textAlign: "left"}}>
                      <p>- Raw EEG importing from exported JSON format</p>
                    </div>
                    <p style={{padding: ".5em", backgroundColor: "blue", color: "white"}}>June 7, 2024 // v.5.5</p>
                    <div style={{textAlign: "left"}}>
                      <p>- Implemented customizable EEG data filtering</p>
                    </div>
                    <p style={{padding: ".5em", backgroundColor: "blue", color: "white"}}>June 2, 2024 // v.5</p>
                    <div style={{textAlign: "left"}}>
                      <p>- Reduction of component state nesting</p>
                      <p>- Raw EEG exporting in JSON format</p>
                    </div>
                  </>
                }
              { /*
                headsetDetail.isConnected && headsetDetail.isStreaming ? (
                  <>
                  <BrainWaveChart />
                  </>
                )
                :
                <>
                </>
                */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
