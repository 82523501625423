import React, { useState } from 'react';

function SetupChecklist({ setScreen, browserSupported }) {

    function changeScreen(e) {
        // Set screen from button event
        setScreen(e.target.value);
    }

    return (
        <>
            <h4
                style={{
                    margin: "auto",
                    textAlign: "center",
                    backgroundColor: "#6BF178",
                    color: "#02111B",
                    marginBottom: ".5em",
                    width: "75%",
                    padding: ".5em",
                    marginTop: "-1.75em",
                    border: "double .25em",
                    lineHeight: "1.2"
                }}
            >
                Startup Checklist
            </h4>
            <div style={{ padding: ".5em" }}>
                <div style={{
                    margin: "auto",
                    display: "grid",
                    gridTemplateColumns: "10% 70% 20%"
                }}>
                    <span style={{ padding: ".5em" }}>
                        *
                    </span>
                    <span style={{ padding: ".5em", textAlign: "center" }}>
                        Supported Browser
                    </span>
                    {
                        browserSupported ? (
                            <>
                                <span style={{ backgroundColor: "#6BF178", color: "#02111b", padding: ".5em", textAlign: "center" }}>
                                    YES
                                </span>
                            </>
                        )
                            :
                            <>
                                <span style={{ backgroundColor: "red", color: "#02111b", padding: ".5em", textAlign: "center" }}>
                                    NO
                                </span>
                            </>
                    }
                </div>
                <div style={{
                    margin: "auto",
                    display: "grid",
                    gridTemplateColumns: "10% 70% 20%"
                }}>
                    <span style={{ padding: ".5em" }}>
                        *
                    </span>
                    <span style={{ padding: ".5em", textAlign: "center" }}>
                        Headset Connected
                    </span>
                    <span style={{ backgroundColor: "red", color: "#02111b", padding: ".5em", textAlign: "center" }}>
                        NO
                    </span>
                </div>
            </div>
            <div style={{
                margin: "auto",
                padding: ".25em",
                textAlign: "center"
            }}>
                {
                    browserSupported ? (
                        <>
                            <span>
                                Browser supported, connect a headset on the next screen.
                            </span>
                        </>
                    )
                        :
                        <>
                            <span>
                                Use another browser to connect a headset.
                            </span>
                        </>
                }
            </div>

        </>
    )
}
export default SetupChecklist
