import React, { useState, useEffect, useRef, useCallback } from 'react'
import Draggable from "react-draggable";
import { Resizable } from 're-resizable';

import { useRecoilState, useSetRecoilState } from 'recoil';
import { DeviceDetail } from '../../recoil/atom/deviceDetail';
import { headsetDetailState } from '../../recoil/atom/headsetDetailState';
import { windowManipulator } from '../../recoil/atom/deviceDetailState';

import BrainWaveChart from './files/BrainWaveChart';
import ViewSessionChart from './files/ViewSessionChart';

function BrainWaveConfig({
    headsetClient
}) {

    const device = DeviceDetail();
    const windowDetails = device.windows.find(w => w.id === "bwv");
    const manipulateWindows = useSetRecoilState(windowManipulator);

    const [selectedTab, setSelectedTab] = useState("Live");
    const [headsetDetail, setHeadsetDetail] = useRecoilState(headsetDetailState);

    const titleBarRef = useRef(null);
    const innerBodyRef = useRef(null);
    const [modalBackgroundHeight, setModalBackgroundHeight] = useState('auto');
    const [modalBackgroundWidth, setModalBackgroundWidth] = useState('auto');
    const [modalInnerHeight, setModalInnerHeight] = useState('auto');

    useEffect(() => {
        if (titleBarRef.current && windowDetails.height) {
            const titleBarHeight = titleBarRef.current.offsetHeight;
            const newHeight = `calc(${windowDetails.height} - ${titleBarHeight}px - 16px)`;
            const newWidth = `calc(${windowDetails.width} - ${titleBarRef.current.offsetWidth})px - 16px`;
            console.log(titleBarHeight);

            setModalBackgroundHeight(newHeight);
            setModalBackgroundWidth(newWidth);

            //
            const innerDivHeight = innerBodyRef.current.offsetHeight;
            const newInnerHeight = `calc(${newHeight} - 25px)`;
            console.log("The new inner height");
            console.log(innerDivHeight);
            setModalInnerHeight(newInnerHeight);
            
            const handleUpdateWindow = () => {
                manipulateWindows({
                    type: 'update',
                    window: {
                        id: windowDetails.id,
                        props: { width: newWidth, height: newHeight }
                    }
                });
            };


        }
    }, [windowDetails]);

    const closeWindow = () => {
        manipulateWindows({
            type: 'remove',
            window: {
                id: windowDetails.id
            }
        })
    }

    const bringToFront = () => {
        manipulateWindows({
            type: 'reindex',
            window: { id: windowDetails.id }
        });
    };

    const handleResize = useCallback((newWidth, newHeight) => {
        manipulateWindows({
            type: 'update',
            window: {
                id: windowDetails.id,
                props: { width: newWidth, height: newHeight}
            }
        });
    }, [manipulateWindows]);

    const onResizeStop = useCallback((e, direction, ref, delta, position) => {
        const newSize = {
            width: ref.style.width,
            height: ref.style.height
        }

        handleResize(newSize.width, newSize.height)
    }, [handleResize])


    function tabSelection(e) {
        setSelectedTab(e.target.textContent);
    }

    function toggleExport(){
        if(headsetDetail.headsetStream.length > 0){
            // Export training samples
            const timestamp = Math.floor(Date.now() / 1000);    

            const dataString = JSON.stringify(headsetDetail.headsetStream);
            const blob = new Blob([dataString], {type: 'application/json'});
            const downloadLink = URL.createObjectURL(blob);
            
            const extractDownload = document.createElement('a');
            extractDownload.href = downloadLink;
            extractDownload.download = `eeg_${timestamp}_${headsetDetail.headsetStream.length}.json`;
            extractDownload.style.display = 'none';
            
            document.body.appendChild(extractDownload);
            extractDownload.click();
            
            document.body.removeChild(extractDownload);
            URL.revokeObjectURL(downloadLink);
        }
    }

    return (
        <>
            {
                windowDetails &&
                <>
                    <Draggable
                        handle="strong"
                        bounds="parent"
                        onStart={bringToFront}>
                        <div
                            className="modal-window-defaults"
                            style={{
                                left: device.width > 850 ? '15%' : '',
                                zIndex: windowDetails.zIndex,
                                fontSize: device.width > 850 ? "1.1em" : "1em"
                            }}
                        >
                            <Resizable 
                            size={{ width: windowDetails.width, height: windowDetails.height }}
                            onResizeStop={onResizeStop}
                            minConstraints={[200, 200]} 
                            maxConstraints={[device.width - 100, device.height - 100]}>
                                <strong className="title-bar" ref={titleBarRef}>

                                    <span
                                        className="modal-title"
                                        onClick={bringToFront}
                                        id="bwv"
                                    >Session Review</span>
                                    <div className="title-tray"
                                    >
                                        <button
                                            className="modal-close"
                                            onClick={() => closeWindow(windowDetails.id)}
                                            onTouchStart={() => closeWindow(windowDetails.id)}
                                        >
                                            X
                                        </button>
                                    </div>
                                </strong>
                                <div
                                    className="modal-window-background"
                                    style={{
                                        backgroundColor: "rebeccapurple",
                                        padding: ".5em",
                                        height: modalBackgroundHeight,  // Dynamic height
                                        width: modalBackgroundWidth
                                    }}
                                >
                                    <div className="message-container" style={{ width: "100%" }} ref={innerBodyRef}>
                                        <div className="tabs">
                                            <button className={selectedTab === "Live" ? 'tab-selected' : 'tab'} onClick={tabSelection}>Live</button>
                                            <button className={selectedTab === "View" ? 'tab-selected' : 'tab'} onClick={tabSelection}>View</button>
                                        </div>
                                        <div className="tab-container">
                                        <div 
                                            style={{ 
                                                backgroundColor: "#02111B", 
                                                margin: ".5em 0.25em 0.25em", 
                                                padding: ".25em", 
                                                border: ".25em inset #6bf178" }}>
                                            <div
                                                style={{
                                                    padding: ".5em",
                                                    backgroundColor: "rebeccapurple"
                                                }}>
                                                <div
                                                    style={{
                                                        border: "ridge .25em #6bf178",
                                                        backgroundColor: "#02111b",
                                                        padding: ".5em"
                                                    }}>
                                                    {
                                                        selectedTab === "Live" &&
                                                        <>
                                                            {
                                                                headsetDetail.isStreaming ? (
                                                                    <>
                                                                        <BrainWaveChart />
                                                                        <p style={{padding: ".5em", backgroundColor: "#02111B", color: "#6BF178"}}>Sample count: {headsetDetail.headsetStream.length}</p>
                                                                        <div style={{display: "flex"}}>
                                                                            <button style={{width: "100%" }}className="button" onClick={toggleExport}>Create a Note</button>
                                                                        </div>
                                                                    </>
                                                                )
                                                                :
                                                                <>
                                                                {
                                                                    headsetDetail.isConnected ? (
                                                                        <>
                                                                            <p style={{color: "#6BF178", backgroundColor: "#02111B", padding: ".5em"}}>Start streaming to view the live feed.</p>
                                                                        </>
                                                                    )
                                                                    :
                                                                    <>
                                                                        <p style={{color: "#6BF178", backgroundColor: "#02111B", padding: ".5em", textAlign: "center"}}>Connect a headset to view the live feed.</p>
                                                                        <button className="button" style={{display: "block", width: "100%", backgroundColor: "lightgrey", color: "red", border: "0.25em outset red"}}>No Headset Connected</button>
                                                                    </>
                                                                }
                                                                </>
                                                            }  
                                                        </>
                                                    }
                                                    {
                                                        selectedTab === "View" &&
                                                        <>
                                                            <ViewSessionChart />
                                                        </>
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Resizable>
                        </div>
                    </Draggable >
                </>
            }
        </>
    )
}

export default BrainWaveConfig