import React, { useState, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { sessionDetailState } from '../../../recoil/atom/sessionDetailState';

function ImportSession({ selectedScreen }) {

    const [sessionDetails, setSessionDetails] = useRecoilState(sessionDetailState);
    const fileInputRef = useRef(null);
    /*
     - Include field information 
     - Update fields with selected session prior to import

    */

    return (
        <>
            <h4
                style={{
                    margin: "auto",
                    textAlign: "center",
                    backgroundColor: "#02111B",
                    color: "#6BF178",
                    marginBottom: ".5em",
                    width: "75%",
                    padding: ".5em",
                    marginTop: "-2.25em",
                    border: "double .25em",
                    lineHeight: "1.2"
                }}
            >
                Import A Session
            </h4>
            <p>
                Load in an existing local session below.
            </p>
            {
                sessionDetails.selectedSession !== null && sessionDetails.selectedSession !== "" && (
                 <>
                    <div style={{margin: ".5em", display: "grid"}}>
                        <span>Session Name:</span>
                        <span style={{
                            backgroundColor: "#02111b",
                            color: "#6bf178",
                            border: "#6bf178 .25em ridge",
                            fontFamily: "Print Char",
                            padding: ".5em",
                            width: "90%",
                            margin: "auto"
                        }} >{sessionDetails.sessions[sessionDetails.selectedSession].meta.name}</span>
                    </div>
                    <div style={{margin: ".5em", display: "grid"}}>
                        <span>Sample Amount:</span>
                        <span style={{
                            backgroundColor: "#02111b",
                            color: "#6bf178",
                            border: "#6bf178 .25em ridge",
                            fontFamily: "Print Char",
                            padding: ".5em",
                            width: "90%",
                            margin: "auto"
                        }} >{sessionDetails.sessions[sessionDetails.selectedSession].samples.length}</span>
                    </div>
                </>   
                )
            }
        </>
    )
}
export default ImportSession
