import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { sessionDetailState } from '../../../recoil/atom/sessionDetailState';

function ExportSession({ selectedScreen }) {

    const [selectedSession, setSelectedSession] = useState(null);
    const [sessionDetails, setSessionDetails] = useRecoilState(sessionDetailState);
    
    console.log(sessionDetails);

    const updateSelectedSession = (e) => {
        const index = e.target.value;
        setSelectedSession(index);
        setSessionDetails( prevDetails => ({
            ...prevDetails,
            selectedSession: index
        }))
    }

    return (
        <>
            <h4
                style={{
                    margin: "auto",
                    textAlign: "center",
                    backgroundColor: "#02111B",
                    color: "#6BF178",
                    marginBottom: ".5em",
                    width: "75%",
                    padding: ".5em",
                    marginTop: "-2.25em",
                    border: "double .25em",
                    lineHeight: "1.2"
                }}
            >
                Export A Session
            </h4>
            <p>
                Export an existing session below.
            </p>
            <div style={{margin: ".5em"}}>
                <select
                    onChange={updateSelectedSession} value={selectedSession}
                    style={{
                        display: "block",
                        margin: "auto",
                        backgroundColor: "#02111B",
                        color: "#6bf178",
                        border: "medium outset #6bf178",
                        padding: ".5em",
                        fontSize: "1em",
                        outline: "#02111b solid .1em"
                    }}
                >
                    <option value="">Select A Session</option>
                    {
                        sessionDetails.sessions.map((session, index)=> (
                            <option key={index} value={index}>{session.meta.name}</option>
                        ))
                    }
                </select>
            </div>
            {
                selectedSession !== null && selectedSession !== "" && (
                 <>
                    <div style={{margin: ".5em"}}>
                        <input placeholder="Enter a name" 
                            value={sessionDetails.sessions[selectedSession].meta.name}
                            style={{
                            backgroundColor: "#02111b",
                            color: "#6bf178",
                            border: "#6bf178 .25em ridge",
                            fontFamily: "Print Char",
                            padding: ".5em",
                            width: "90%",
                            margin: "auto"
                        }} />
                    </div>
                    <div style={{margin: ".5em"}}>
                        <textarea 
                            placeholder="Enter a description" 
                            value={sessionDetails.sessions[selectedSession].meta.description}
                            style={{
                                backgroundColor: "#02111b",
                                color: "#6bf178",
                                border: "#6bf178 .25em ridge",
                                fontFamily: "Print Char",
                                padding: ".5em",
                                width: "90%",
                                margin: "auto",
                                minHeight: "5em"
                                
                            }} />
                    </div>
                 </>   
                )
            }
        </>
    )
}
export default ExportSession
