import React from 'react';
import { createGlobalStyle } from 'styled-components';
import Sidebar from './Sidebar';

const LogoPageOverride = createGlobalStyle`

    .logo-fade {
        animation: fadeIn 5s;
        -webkit-animation: fadeIn 5s;
        -moz-animation: fadeIn 5s;
        -o-animation: fadeIn 5s;
        -ms-animation: fadeIn 5s;
      }
      
      @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }
      
      @-moz-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }
      
      @-webkit-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }
      
      @-o-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }
      
      @-ms-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }

`;


const logoButtonStyle = {
    fontSize: "1.1em",
    padding: ".25em",
    margin: "auto",
    textIndent: "1em"
}


function Taskbar({
    menuClick,
    toggleAuth,
    menuEnabled,
    headsetClient,
    setHeadsetClient,
    headsetDataStream,
    setHeadsetDataStream
}) {

    return (
        <>
            <LogoPageOverride />
            <div
                style={{
                    position: "sticky",
                    top: 0,
                    zIndex: "9999",
                    width: "100%",
                    display: "flex",
                    borderBottom: "solid .1em",
                    maxHeight: "100%",
                    backgroundColor: "rgb(2 17 27 / 82%)"
                }}>
                <div
                    className="icon-name"
                    style={{
                        display: "flex",
                        maxWidth: "100%",
                        margin: "auto"
                    }}>
                    <button style={{
                        backgroundColor: "rgba(0,0,0,0)",
                        border: "none",
                        color: "#6BF178",
                        display: "flex",
                        letterSpacing: "1em",
                        fontWeight: "600"
                    }}
                        onClick={() => menuClick()}
                    ><span className="printchar logo-fade" style={logoButtonStyle}>FUTURIST</span>
                    </button>
                </div>
            </div>
            {
                menuEnabled &&
                <>
                    <Sidebar
                        login={toggleAuth}
                        menuClick={menuClick}
                        menuEnabled={menuEnabled}
                        headsetDataStream={headsetDataStream}
                    />
                </>
            }

        </>
    )
}

export default Taskbar