import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useRecoilState } from 'recoil';
import { headsetDetailState } from '../../recoil/atom/headsetDetailState';
import { headsetStreamSelector, isStreamingSelector } from '../../recoil/atom/headsetDetailSelector';
import React, { useState, useEffect, createRef } from 'react';

function BrainWaveChart() {

    const [chartData, setChartData] = useState([]);
    const [headsetDetail, setHeadsetDetail] = useRecoilState(headsetDetailState)
    const isStreaming = useRecoilState(isStreamingSelector);

    useEffect(() => {
        if (isStreaming) {
            const interval = setInterval(() => {
                updateChart();
            }, 300);
            return () => clearInterval(interval);
        }
    }, [chartData]);

    function updateChart() {
        // log existing chart
        console.log(chartData);
        let latest_stream_data = headsetDetail.headsetStream[headsetDetail.headsetStream.length - 1];
        let latest_time = (latest_stream_data.info.startTime / 1000) - headsetDetail.streamStartTime;
        latest_time = Math.round(latest_time * 2) / 2;
    
        let latest_chart_data = {
            name: latest_time.toString()
        };
        let channels = latest_stream_data.psd;
        for (let c = 0; c < channels.length; c++) {
            
            let channel_average = 0;

            for (let d = 0; d < channels[c].length; d++) {
                channel_average += channels[c][d];
            }
            channel_average = channel_average / channels[c].length;
            
            let channel_name = "";


            if(c == 0) {
                channel_name = "TP9";
            }
            if(c == 1) {
                channel_name = "AF7";
            }
            if(c == 2) {
                channel_name = "AF8";
            }
            if(c == 3) {
                channel_name = "TP10";
            }
            latest_chart_data[channel_name] = channel_average;
        }
        //console.log("Newest chart data:", latest_chart_data);
        let data = chartData;
        /*
        let new_data = {
            name: Date.now().toString(),
            Fp1: Math.floor(Math.random() * 90),
            Fp2: Math.floor(Math.random() * 90),
            TP9: Math.floor(Math.random() * 90),
            TP10: Math.floor(Math.random() * 90)
        }
        */
        if (data.length > 10) {
            data = [...data.slice(-10), latest_chart_data]
        } else {
            data = [...data, latest_chart_data];
        }
        setChartData(data);
    }

    return (
        <>
            {chartData.length > 0 &&
                <>
                    <ResponsiveContainer aspect={2}>
                        <LineChart
                            width={500}
                            height={300}
                            data={chartData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="TP9" stroke="#8884d8" activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="AF7" stroke="#FF0000" />
                            <Line type="monotone" dataKey="AF8" stroke="cyan" />
                            <Line type="monotone" dataKey="TP10" stroke="#82ca9d" />

                        </LineChart>
                    </ResponsiveContainer>
                </>
            }
        </>
    )
}
export default BrainWaveChart
