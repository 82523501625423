import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useRecoilState } from 'recoil';
import { headsetDetailState } from '../../../recoil/atom/headsetDetailState';
import { headsetStreamSelector, isStreamingSelector } from '../../../recoil/atom/headsetDetailSelector';
import React, { useState, useEffect, createRef } from 'react';
import { sessionDetailState } from '../../../recoil/atom/sessionDetailState';

function ViewSessionChart() {

    const [chartData, setChartData] = useState([]);
    const [headsetDetail, setHeadsetDetail] = useRecoilState(headsetDetailState)
    const isStreaming = useRecoilState(isStreamingSelector);

    const [selectedSession, setSelectedSession] = useState("");
    const [sessionDetails, setSessionDetails] = useRecoilState(sessionDetailState);
    
    console.log(sessionDetails);

    const updateSelectedSession = (e) => {
        const index = parseInt(e.target.value);
        setSelectedSession(index);
        setSessionDetails( prevDetails => ({
            ...prevDetails,
            selectedSession: index
        }))
        updateChart();
    }

    const handleInputChange = (index, key, value) => {
        setSessionDetails(prevDetails => {
            const updatedSessions = prevDetails.sessions.map((session, i) => 
                i === parseInt(index) ? { ...session, meta: { ...session.meta, [key]: value } } : session
            );
            return {
                ...prevDetails,
                sessions: updatedSessions
            };
        });
    };
    

    function updateChart() {
        console.log("Running update chart. .");

        if(sessionDetails.selectedSession !== null && sessionDetails.selectedSession !== "") {
            console.log("Passing");

            let new_chart_sample = [...chartData];

            for(let i=0;i<sessionDetails.sessions[sessionDetails.selectedSession].samples.length;i++) {
                let current_session = sessionDetails.sessions[sessionDetails.selectedSession];
                console.log("Current session:", current_session)
                let stream_sample = current_session.samples[i];
                console.log("Current Sample", stream_sample)
                let sample_timestamp = (stream_sample.info.startTime / 1000) - current_session.meta.startTime;
                console.log(stream_sample.info.startTime - current_session.meta.startTime);
                let chart_data_sample = {
                    name: (Math.round(sample_timestamp * 2) / 2).toString()
                }

                console.log(chart_data_sample);

                let channels = stream_sample.psd;
                for (let c = 0; c < channels.length -1; c++) {
                    let channel_average = 0;

                    for (let d = 0; d < channels[c].length; d++) {
                        channel_average += channels[c][d];
                    }
                    channel_average = channel_average / channels[c].length;
                    
                    let channel_name = "";

                    if(c == 0) {
                        channel_name = "TP9";
                    }
                    if(c == 1) {
                        channel_name = "AF7";
                    }
                    if(c == 2) {
                        channel_name = "AF8";
                    }
                    if(c == 3) {
                        channel_name = "TP10";
                    }
                    chart_data_sample[channel_name] = channel_average;

                }
                console.log("Chart data sample", chart_data_sample);
                new_chart_sample.push(chart_data_sample)           
            }
            console.log("New chart sample: ", new_chart_sample)
            
            setChartData(new_chart_sample);

        }
            console.log(chartData);

    }

    return (
        <>
            <h4
                style={{
                    margin: "auto",
                    textAlign: "center",
                    backgroundColor: "#02111B",
                    color: "#6BF178",
                    marginBottom: ".5em",
                    width: "75%",
                    padding: ".5em",
                    marginTop: "-2.25em",
                    border: "double .25em",
                    lineHeight: "1.2"
                }}
            >
                Load Session
            </h4>
            {chartData.length > 0 ?
                (
                    <>
                        <ResponsiveContainer aspect={2}>
                            <LineChart
                                width={500}
                                height={300}
                                data={chartData}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="TP9" stroke="#8884d8" />
                                <Line type="monotone" dataKey="AF7" stroke="#FF0000" />
                                <Line type="monotone" dataKey="AF8" stroke="cyan" />
                                <Line type="monotone" dataKey="TP10" stroke="#82ca9d" />

                            </LineChart>
                        </ResponsiveContainer>
                        <p>Chart data loaded {chartData.length}</p>
                </>
                )
                :
                <>
                    <p>
                        Load an existing sessions and view the chart data below.
                    </p>
                </>
            }
            <div style={{margin: ".5em"}}>
                <select
                    onChange={updateSelectedSession}
                    value={selectedSession}
                    style={{
                        display: "block",
                        margin: "auto",
                        backgroundColor: "#02111B",
                        color: "#6bf178",
                        border: "medium outset #6bf178",
                        padding: ".5em",
                        fontSize: "1em",
                        outline: "#02111b solid .1em"
                    }}
                >
                    <option value={""} onChange={updateSelectedSession} >Select A Session</option>
                    {
                        sessionDetails.sessions.map((session, index)=> (
                            <option key={index} value={index} onChange={updateSelectedSession}>{session.meta.name}</option>
                        ))
                    }
                </select>
            </div>
        </>
    )
}
export default ViewSessionChart
