import React, { useState } from 'react';
import { BrowserDetail } from '../../../recoil/atom/browserDetail';

function SetupIntro({ setScreen }) {

    const browser = BrowserDetail();

    function changeScreen(e) {
       setScreen(e.target.value)
    }

    return (
        <>
            <h4
                style={{
                    margin: "auto",
                    textAlign: "center",
                    backgroundColor: "#02111B",
                    color: "#6BF178",
                    marginBottom: ".5em",
                    width: "75%",
                    padding: ".5em",
                    marginTop: "-1.75em",
                    border: "double .25em",
                    lineHeight: "1.2"
                }}
            >
                Getting Started
            </h4>
                <p
                    style={{
                        margin: "auto",
                        padding: ".5em"
                    }}
                >
                    Welcome to Futurist!
                </p>
                <p
                    style={{
                        margin: "auto",
                        padding: ".5em"
                    }}
                >
                    This wizard connects compatible headsets with supported browsers.
                </p>
                <p
                    style={{
                        margin: "auto",
                        padding: ".5em"
                    }}
                >
                    Review the Project Documents for more information.
                </p>
            </>
    )
}
export default SetupIntro
