import { useState } from 'react';

import { useRecoilState } from 'recoil';
import { trainingStatusSelector } from '../../../../recoil/atom/modelDetailSelector';

export default function TrainingStatus() {
    const [status, setTrainingStatus] = useRecoilState(trainingStatusSelector);

    return (
        <>
            <div style={{ padding: ".5em", border: "#6bf178 ridge .25em", marginBottom: ".5em", backgroundColor: "#02111b" }}>
                <div style={{
                    margin: "auto",
                    display: "grid",
                    gridTemplateColumns: "80% 20%"
                }}>
                    <span style={{ padding: ".5em", textAlign: "center" }}>
                        Data Captured
                    </span>
                    {
                        status > 0 &&
                        <span style={{ backgroundColor: "#6BF178", color: "#02111b", padding: ".5em", textAlign: "center" }}>
                            DONE
                        </span>
                    }
                    {
                        status === 0 &&
                        <span style={{ backgroundColor: "YELLOW", color: "#02111b", padding: ".5em", textAlign: "center" }}>
                            WAIT
                        </span>
                    }
                    {
                        status < 0 &&
                        <span style={{ backgroundColor: "red", color: "#02111b", padding: ".5em", textAlign: "center" }}>
                            NO
                        </span>
                    }
                </div>
                <div style={{
                    margin: "auto",
                    display: "grid",
                    gridTemplateColumns: "80% 20%"
                }}>
                    <span style={{ padding: ".5em", textAlign: "center" }}>
                        Tensors Generated
                    </span>
                    {
                        status > 1 &&
                        <span style={{ backgroundColor: "#6BF178", color: "#02111b", padding: ".5em", textAlign: "center" }}>
                            DONE
                        </span>
                    }
                    {
                        status === 1 &&
                        <span style={{ backgroundColor: "YELLOW", color: "#02111b", padding: ".5em", textAlign: "center" }}>
                            WAIT
                        </span>
                    }
                    {
                        status < 1 &&
                        <span style={{ backgroundColor: "red", color: "#02111b", padding: ".5em", textAlign: "center" }}>
                            NO
                        </span>
                    }
                </div>
                <div style={{
                    margin: "auto",
                    display: "grid",
                    gridTemplateColumns: "80% 20%"
                }}>
                    <span style={{ padding: ".5em", textAlign: "center" }}>
                        Models Created
                    </span>
                    {
                        status > 2 &&
                        <span style={{ backgroundColor: "#6BF178", color: "#02111b", padding: ".5em", textAlign: "center" }}>
                            DONE
                        </span>
                    }
                    {
                        status === 2 &&
                        <span style={{ backgroundColor: "YELLOW", color: "#02111b", padding: ".5em", textAlign: "center" }}>
                            WAIT
                        </span>
                    }
                    {
                        status < 2 &&
                        <span style={{ backgroundColor: "red", color: "#02111b", padding: ".5em", textAlign: "center" }}>
                            NO
                        </span>
                    }
                </div>
                <div style={{
                    margin: "auto",
                    display: "grid",
                    gridTemplateColumns: "80% 20%"
                }}>
                    <span style={{ padding: ".5em", textAlign: "center" }}>
                        Model Compiled
                    </span>
                    {
                        status > 3 &&
                        <span style={{ backgroundColor: "#6BF178", color: "#02111b", padding: ".5em", textAlign: "center" }}>
                            DONE
                        </span>
                    }
                    {
                        status === 3 &&
                        <span style={{ backgroundColor: "YELLOW", color: "#02111b", padding: ".5em", textAlign: "center" }}>
                            WAIT
                        </span>
                    }
                    {
                        status < 3 &&
                        <span style={{ backgroundColor: "red", color: "#02111b", padding: ".5em", textAlign: "center" }}>
                            NO
                        </span>
                    }
                </div>
                <div style={{
                    margin: "auto",
                    display: "grid",
                    gridTemplateColumns: "80% 20%"
                }}>
                    <span style={{ padding: ".5em", textAlign: "center" }}>
                        Model Trained
                    </span>
                    {
                        status === 4 &&
                        <span style={{ backgroundColor: "#6BF178", color: "#02111b", padding: ".5em", textAlign: "center" }}>
                            DONE
                        </span>
                    }
                    {
                        status < 4 &&
                        <span style={{ backgroundColor: "red", color: "#02111b", padding: ".5em", textAlign: "center" }}>
                            NO
                        </span>
                    }
                </div>
            </div>
        </>
    )
}