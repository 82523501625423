/*
In-progress conversion to recoil from redux
*/

import { atom } from 'recoil';

export const headsetDetailState = atom({
    key: "headsetDetailState",
    default: {
        headsetClient: null,
        isConnected: false,
        isStreaming: false,
        streamStartTime: null,
        headsetStream: [],
        sliceFFTSettings: {
            enableSliceFFT: true,
            minFFT: 2,
            maxFFT: 30
        },
        enablePowerByBand: false,
        notchFilterSettings: {
            enableNotchFilter: false,
            nbChannels: 4,
            cutoffFrequency: 60,
            bandWidth: .5,
            filterHarmonics: false,
            samplingRate: 256,
            characteristic: 'butterworth',
            order: 2
        },
        lowPassFilterSettings: {
            enableLowPassFilter: false,
            nbChannels: 4,
            cutoffFrequency: 50,
            samplingRate: 256,
            characteristic: 'butterworth',
            order: 2
        },
        highPassFilterSettings: {
            enableHighPassFilter: false,
            nbChannels: 4,
            cutoffFrequency: 2,
            samplingRate: 256,
            characteristic: 'butterworth',
            order: 2
        },
        bandPassFilterSettings: {
            enableBandPassFilter: false,
            nbChannels: 4,
            lowCutOff: 2,
            highCutOff: 50,
            samplingRate: 256,
            characteristic: 'butterworth',
            order: 2
        },
        settings: {
            cutOffLow: 2,
            cutOffHigh: 50,
            interval: 100,
            bins: 256,
            duration: 512,
            srate: 256,
            sliceFFTLow: 1,
            sliceFFTHigh: 30,
            name: 'Bands',
            secondsToSave: 10
        }
    }
});