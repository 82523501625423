import React, { useState, useContext, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import Draggable from "react-draggable";
import { Resizable } from 're-resizable';

import { useRecoilState, useSetRecoilState } from 'recoil';
import { DeviceDetail } from '../../recoil/atom/deviceDetail';
import { windowManipulator } from '../../recoil/atom/deviceDetailState';

import DataContext from '../../contexts/dataContext';
import Item from './files/Item';
import ContentFactory from './files/ContentFactory';
import Weblink from '../projectexplorer/files/WebShortcut';

function ExplorerModal() {

    // Explorer Files
    const data = useContext(DataContext);
    const [items, setItems] = useState([]);

    useEffect(
        () => {
            const files = data.getItems();
            setItems(files);
        }, [data]);

    const device = DeviceDetail();
    const windowDetails = device.windows.find(w => w.id === "xplorer");
    const manipulateWindows = useSetRecoilState(windowManipulator);

    const [selectedValue, setSelectedValue] = useState('');
    const [selectedTab, setSelectedTab] = useState("General");
    const [selectedItem, setSelectedItem] = useState(null);

    const titleBarRef = useRef(null);
    const innerBodyRef = useRef(null);
    const [modalBackgroundHeight, setModalBackgroundHeight] = useState('auto');
    const [modalBackgroundWidth, setModalBackgroundWidth] = useState('auto');
    const [modalInnerHeight, setModalInnerHeight] = useState('auto');

    useEffect(() => {
        if (titleBarRef.current && windowDetails.height) {
            const titleBarHeight = titleBarRef.current.offsetHeight;
            const newHeight = `calc(${windowDetails.height} - ${titleBarHeight}px - 16px)`;
            const newWidth = `calc(${windowDetails.width} - ${titleBarRef.current.offsetWidth})px - 16px`;
            console.log(titleBarHeight);

            setModalBackgroundHeight(newHeight);
            setModalBackgroundWidth(newWidth);

            //
            const innerDivHeight = innerBodyRef.current.offsetHeight;
            const newInnerHeight = `calc(${newHeight} - 25px)`;
            console.log("The new inner height");
            console.log(innerDivHeight);
            setModalInnerHeight(newInnerHeight);
            
            const handleUpdateWindow = () => {
                manipulateWindows({
                    type: 'update',
                    window: {
                        id: windowDetails.id,
                        props: { width: newWidth, height: newHeight }
                    }
                });
            };


        }
    }, [windowDetails]);

    const closeWindow = () => {
        manipulateWindows({
            type: 'remove',
            window: {
                id: windowDetails.id
            }
        })
    }

    const bringToFront = () => {
        manipulateWindows({
            type: 'reindex',
            window: { id: windowDetails.id }
        });
    };

    const handleResize = useCallback((newWidth, newHeight) => {
        manipulateWindows({
            type: 'update',
            window: {
                id: windowDetails.id,
                props: { width: newWidth, height: newHeight}
            }
        });
    }, [manipulateWindows]);

    const onResizeStop = useCallback((e, direction, ref, delta, position) => {
        const newSize = {
            width: ref.style.width,
            height: ref.style.height
        }

        if(parseInt(newSize.width) > (device.width - 20)) {
            newSize.width = `${device.width - 20}px`;
        }

        if(parseInt(newSize.height) > (device.height - 55)) {
            newSize.height = `${device.height - 55}px`;
        }
    
        handleResize(newSize.width, newSize.height)            
        

    }, [handleResize])

    function tabSelection(e) {
        setSelectedTab(e.target.textContent);
    }

    function itemSelection(index) {
        setSelectedItem(items[index]);
    }

    function clearItem() {
        setSelectedItem(null);
    }

    function renderContent() {
        return items.map((item, index) => (
            <>
                {(item.type === selectedTab && selectedTab === 'General') && (
                    <Item
                        key={index}
                        index={index}
                        name={item.name}
                        icon={item.icon}
                        itemSelection={itemSelection}
                    />
                )}
                {
                    item.type === selectedTab && selectedTab === 'Sessions' && (
                        <Item
                            key={index}
                            name={item.name}
                            icon={item.icon}
                            itemSelection={itemSelection}
                        />  
                    )
                }
            </>
        ));
    }

    return (
        <>
            {
                windowDetails &&
                <>
                    <Draggable
                        handle="strong"
                        bounds="parent"
                        onStart={bringToFront}>
                        <div
                            className="modal-window-defaults"
                            style={{
                                left: device.width > 850 ? '15%' : '',
                                zIndex: windowDetails.zIndex
                            }}
                        >
                            <Resizable 
                                size={{ width: windowDetails.width, height: windowDetails.height }}
                                onResizeStop={onResizeStop}
                                minConstraints={[200, 200]} 
                                maxConstraints={[device.width - 100, device.height - 100]}>
                                <strong className="title-bar" ref={titleBarRef}>

                                <span
                                    className="modal-title"
                                    onClick={bringToFront}
                                    id="xplorer"
                                >File Xplorer</span>
                                <div className="title-tray"
                                >
                                    <button
                                        className="modal-close"
                                        onClick={() => closeWindow(windowDetails.id)}
                                        onTouchStart={() => closeWindow(windowDetails.id)}>
                                        X
                                    </button>
                                </div>
                            </strong>
                            <div 
                                className="modal-window-content"
                                onClick={bringToFront}
                                id="xplorer">
                                <div
                                className="modal-window-background"
                                style={{
                                    height: modalBackgroundHeight,  // Dynamic height
                                    width: modalBackgroundWidth}}>
                                <div className="message-container" style={{ width: "100%" }} ref={innerBodyRef}>
                                    <div className="tabs">
                                        <button className={selectedTab === "General" ? 'tab-selected' : 'tab'} onClick={tabSelection}>General</button>
                                        <button className={selectedTab === "Sessions" ? 'tab-selected' : 'tab'} onClick={tabSelection}>Sessions</button>
                                    </div>
                                    <div className="tab-container">
                                        {
                                            !selectedItem &&
                                            <>
                                                <div 
                                            style={{ 
                                                backgroundColor: "#02111B", 
                                                margin: ".5em 0.25em 0.25em", 
                                                padding: ".25em", 
                                                border: ".25em inset #6bf178" }}>

                                            <div
                                                style={{
                                                    padding: ".5em",
                                                    backgroundColor: "rebeccapurple"
                                                }}>
                                                <div
                                                    style={{
                                                        border: "ridge .25em #6bf178",
                                                        backgroundColor: "#02111b",
                                                        padding: ".5em"
                                                    }}>
                                                    {
                                                        selectedTab === "General" &&
                                                        <>
                                                            <div style={{ display: "grid", gridTemplateColumns: "repeat(2,50%)", backgroundColor: "#6bf178", color: "#02111b" }}>
                                                                {renderContent()}
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        selectedTab === "Sessions" &&
                                                        <>
                                                        <div style={{ display: "grid", gridTemplateColumns: "repeat(2,50%)", backgroundColor: "#6bf178", color: "#02111b" }}>
                                                                {renderContent()}
                                                            </div> 
                                                        </>
                                                    }
                                                    {
                                                        selectedTab === "Gallery" &&
                                                        <>
                                                        
                                                        </>
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                            </>
                                        }
                                            <div>
                                                {
                                                    selectedItem ? (
                                                        <>
                                                            <button className="button" style={{width: "100%", backgroundColor: "red", color: "white", borderColor: "white"}} onClick={clearItem}>Close {selectedItem.name}</button>
                                                        </>
                                                    )
                                                    :
                                                    <>
                                                        <button className="button" style={{width: "100%"}}>Select A Document</button>
                                                    </>
                                                }
                                            </div>
                                            {
                                                selectedItem &&
                                                    <>
                                                        <div 
                                                            style={{ 
                                                            backgroundColor: "#02111B", 
                                                            margin: ".5em 0.25em 0.25em", 
                                                            padding: ".25em", 
                                                            border: ".25em inset #6bf178" }}>

                                                        <div
                                                            style={{
                                                                padding: ".5em",
                                                                backgroundColor: "rebeccapurple"
                                                            }}>
                                                            <div
                                                                style={{
                                                                    border: "ridge .25em #6bf178",
                                                                    backgroundColor: "#02111b",
                                                                    padding: ".5em",
                                                                    maxHeight: "20em",
                                                                    overflowY: "scroll"
                                                                }}>
                                                                    <ContentFactory id={selectedItem.id} bringToFront={bringToFront}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </Resizable>
                        </div>
                    </Draggable>
                </>
            }
        </>
    )
}

export default ExplorerModal
