import {React, useState, useEffect, useRef} from 'react'
import Draggable from "react-draggable";

import { DeviceDetail } from '../../recoil/atom/deviceDetail';

function SuperHighway({ windowTop, renderWindows, superhighwayToggle, selectedLink }) {

    const [currentURL, setCurrentURL] = useState(selectedLink.url);
    const [htmlContent, setHtmlContent] = useState('');
    const htmlRef = useRef(null);

    useEffect(() => {
        fetch(`https://futurist.io/fetch?url=${currentURL}`, {
            method: 'GET'
        })
        .then(response => response.text())
        .then(html => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
            const fetchedBody = doc.body;
            console.log(fetchedBody);
            const background = fetchedBody.getAttribute('background');
            if (background) {
                const containerDiv = document.createElement('div');
                containerDiv.style.backgroundImage = `url(${background})`;

                containerDiv.innerHTML = fetchedBody.innerHTML;
                console.log(containerDiv.outerHTML);
                setHtmlContent(containerDiv.outerHTML);
            } else {
                setHtmlContent(fetchedBody.innerHTML);
            }
        })
        .catch(error => {
            console.error("Bad error fetching", error);
        });
    }, [currentURL]);

    useEffect(() => {
        const handleClick = (event) => {
            if (event.target.tagName === 'A' || event.target.tagName === 'BUTTON') {
                event.preventDefault();
                const href = event.target.getAttribute('href');
                setCurrentURL(href);
            }
        };

        const container = htmlRef.current;
        container.addEventListener('click', handleClick);

        return () => {
            container.removeEventListener('click', handleClick);
        };
    }, [htmlContent]);

    const device = DeviceDetail();
    let xplorer_height = null;

    if(device.width > 850) {
        xplorer_height = "50vh";
    } else {
        xplorer_height = "80vh";
    }

    return (
        <Draggable
            handle="strong"
            bounds="parent"
            onStart={renderWindows}>
            <div
                className="modal-window-defaults"
                style={{
                    left: device.width > 850 ? '15%' : '',
                    top: device.width > 850 ? '10%': '0',
                    width: device.appWidth,
                    maxHeight: '100%',
                    zIndex: windowTop === "superhighway" ? 1000 : 999,
                    fontSize: device.width > 850 ? "1.1em" : "1em"
                }}
            >
                <strong
                    className="title-bar"
                >
                    <span
                        className="modal-title"
                        onClick={renderWindows}
                        id="superhighway">NetXplorer</span>
                    <div className="title-tray"
                    >
                        <button
                            className="modal-close"
                            onClick={superhighwayToggle}
                            onTouchStart={() => superhighwayToggle()}
                        >
                            X
                        </button>
                    </div>
                </strong>
                <div className="modal-window-background" style={{height: xplorer_height}}>
                    <div className="modal-window-container" style={{marginTop: 0, overflow: "scroll"}}>
                        <div ref={htmlRef} dangerouslySetInnerHTML={{__html: htmlContent}} />  
                    </div>
                </div>
            </div>
        </Draggable>
    )
}

export default SuperHighway
