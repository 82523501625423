import React, { useState } from 'react';
import TrainingChecklist from './training/TrainingChecklist';
function BrainIntro({ setScreen }) {
    const [selectedStatus, setSelectedStatus] = useState("getting_started");

    function changeScreen(e) {
        setSelectedStatus(e.target.value)
    }

    return (
        <>
            <div>
                <select
                    style={{
                        display: "block",
                        margin: "auto",
                        backgroundColor: "#02111B",
                        color: "#6bf178",
                        position: "relative",
                        border: "outset #6BF178 medium",
                        fontSize: "1em",
                        padding: ".5em",
                        outline: "solid .1em #02111B"
                    }}
                    onChange={changeScreen}
                >
                    <option value="getting_started">Headset Checklist</option>
                    <option value="how_this_works">How This Works</option>
                    <option value="data_privacy">What About My Data?</option>
                </select>
            </div>
            <div 
                style={{ 
                    backgroundColor: "#02111B", 
                    margin: ".5em 0.25em 0.25em", 
                    padding: ".25em", 
                    border: ".25em inset #6bf178" }}>

                <div
                    style={{
                        padding: ".5em",
                        backgroundColor: "rebeccapurple"
                    }}>

                    {
                        selectedStatus === "getting_started" &&
                        <>
                            <TrainingChecklist />
                        </>
                    }
                    {
                        selectedStatus === "how_this_works" &&
                        <>
                            <div style={{
                                border: "ridge .25em #6bf178",
                                padding: ".5em",
                                backgroundColor: "#02111B",
                                textAlign: "center"
                            }}>
                                <p>This application uses the Web Bluetooth API built within certain browsers to connect with EEG headsets.</p>
                                <p>Reference the drop down for a list of supported browsers and headsets.</p>
                            </div>
                        </>
                    }
                    {
                        selectedStatus === "supported_browsers" &&
                        <>
                            <p>This is currently only available in Chromium-based browsers (excluding Brave), such as:</p>
                            <p>
                                - Chrome <br />
                                - Microsoft Edge <br />
                                - Opera <br />
                                - Chromium <br />
                                - Samsung Browser <br />
                            </p>
                            <p>
                                On Linux platforms, web bluetooth must be enabled within the browser.
                            </p>
                            <p>
                                This is available on Android <strong>but not iOS</strong>.
                            </p>

                        </>
                    }
                    {
                        selectedStatus === "data_privacy" &&
                        <>
                            <p>This site connects to a headset that quite literally reads your brain.</p>
                            <p>What do you think the implications of someone having that data could be?</p>
                            <p>To be honest, there is a (very) high chance you give up more of what you think on social platforms, where the value of your thoughts, connected to your mobile number and / or email address, on a subject are most likely not reciprocated.</p>
                            <p>Rest assured, this site does not "collect" or store any information. It is one of the few sites left that do not require cookies.</p>
                        </>
                    }
                </div>
            </div>
        </>
    )
}
export default BrainIntro
