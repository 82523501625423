import React, { useState } from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import { isStreamingSelector } from '../../../recoil/atom/headsetDetailSelector';
import { browserDetailState } from '../../../recoil/atom/browserDetailState';
import { MuseClient, zipSamples } from 'muse-js';
import { headsetDetailState } from '../../../recoil/atom/headsetDetailState';

function SetupConnect({
    browserSupported,
    headsetClient,
    setHeadsetClient
}) {
    const [selectedTab, setSelectedTab] = useState("Connect");
    const [selectedAboutOption, setSelectedAboutOption] = useState("data_privacy");

    // Recoil
    const browserDetail = useRecoilValue(browserDetailState);
    const [headsetState, setHeadsetState] = useRecoilState(headsetDetailState);
    const [isStreaming, setIsStreaming] = useRecoilState(isStreamingSelector);

    async function toggleBluetooth() {
        if(!headsetState.isConnected) {
            connectBluetooth()
        } else {
            disconnectBluetooth()
        }
    }

    async function connectBluetooth() {
        let client = new MuseClient();
        //console.log(client);
        try {
            client.enablePpg = true;

            await client.connect();
            setHeadsetClient(client);
            await client.start();

            client.eventMarkers.subscribe((event) => {
                console.log(event);
            })
            setHeadsetState(prevState => ({
                ...prevState,
                isConnected: true
            }));
            /*
            client.telemetryData.subscribe(telemetry => {
                console.log('== Telemetry ==');
                console.log(telemetry);
            });
            client.accelerometerData.subscribe(acceleration => {
                console.log('== Acceler ==')
                console.log(acceleration);
            });
            */
        } catch (err) {
            console.log(err);
            console.log("Unable to connect headset!")
        }
    }

    async function disconnectBluetooth() {
        if (isStreaming) {
            await headsetClient.pause();
        }
        await headsetClient.disconnect();
        setHeadsetClient(null);
        setHeadsetState(prevState => ({
            ...prevState,
            isConnected: false
        }));
    }

    return (
        <>
            <h4 className="modal-window-heading">
                Headset Connection
            </h4>
            <div>
                {
                    selectedTab === "Connect" &&
                    <>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "50% 50%",
                            backgroundColor: "#02111b",
                            padding: ".5em"
                        }}>
                            <span style={{ margin: "auto", padding: ".5em", textAlign: "center" }}>
                                Browser:
                            </span>
                            {
                                browserDetail.is_supported ? (
                                    <>
                                        <span style={{ backgroundColor: "#6BF178", color: "#02111b", padding: ".5em", textAlign: "center", border: "#6BF178 .25em ridge" }}>
                                            {browserDetail.browserName}
                                        </span>
                                    </>
                                )
                                    :
                                    <>
                                        <span style={{ backgroundColor: "red", color: "#02111b", padding: ".5em", textAlign: "center", border: "red .25em ridge" }}>
                                            {browserDetail.browserName}
                                        </span>
                                    </>
                            }
                        </div>
                        {
                            browserSupported ? (
                                <>

                                    <p style={{ textAlign: "center" }}>Connect a supported headset below:</p>
                                    <div style={{ display: "flex", margin: "1em" }}>
                                        <button className="button" style={{ margin: "auto", display: "block" }} onClick={connectBluetooth} >{headsetClient ? "Disconnect Headset" : "Connect Headset"}</button>
                                    </div>
                                </>
                            )
                                :
                                <>

                                    <p>This device is not setup for Web Bluetooth.</p>
                                    {
                                        browserDetail.osName == "iOS" ? (
                                            <>
                                            <p>
                                                A feature (Web Bluetooth) requires a specific browser on iOS.
                                            </p>
                                            <p>
                                                <a style={{backgroundColor: "#6bf178", color: "#02111b"}} href="https://apps.apple.com/us/app/bluefy-web-ble-browser/id1492822055">Download the app here</a>, or use another platform to continue.
                                            </p>
                                            </>
                                        )
                                        :
                                        <>
                                        {
                                            browserDetail.osName == "Linux" ? (
                                                <>
                                            <p>
                                                Linux support requires a Chromium-based browser, in addition to an experimental flag.
                                            </p>
                                            <p>
                                                In {browserDetail.browserName}, visit <span style={{backgroundColor: "#6bf178", color: "#02111b"}} >chrome://flags</span>, search <span style={{backgroundColor: "#6bf178", color: "#02111b"}} >bluetooth</span> and enable <span style={{backgroundColor: "#6bf178", color: "#02111b"}} >Web Bluetooth</span>.
                                            </p>
                                            </>
                                            )
                                            :
                                            <>
                                                {
                                                    browserDetail.browserName == "Brave" ? (
                                                        <>
                                                            <p>
                                                                Brave requires an experimental flag for Web Bluetooth to be enabled.
                                                            </p>
                                                            <p>
                                                                Visit <span>chrome://flags</span>, search <span>bluetooth</span> and enable <span>Web Bluetooth API</span>.
                                                            </p>
                                                        </>
                                                    )
                                                    :
                                                    <>
                                                        <p>This feature is available on most Chromium browsers for all platforms (minus iOS).</p>
                                                        <p>Read our Supported Browsers guide for more info.</p>
                                                    </>
                                                }
                                            </>
                                        }
                                        </>
                                    }
                                </>
                        }
                    </>
                }
            </div>
        </>
    )
}
export default SetupConnect
