import React, { useState, useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import { DeviceDetail } from '../recoil/atom/deviceDetail';
import Draggable from 'react-draggable';

import ReadmeLaunch from '../applications/ReadmeLaunch.js/ReadmeLaunch';
const LoginPageOverride = createGlobalStyle`

@keyframes animate {
0% {
  width: 0px;
}
100% {
  width: 100%;
}
}
@keyframes fadeIn {
0% { opacity: 0; }
100% { opacity: 1; }
}
.blinking-cursor {
font-weight: 100;
color: white;
-webkit-animation: 1s blink step-end infinite;
-moz-animation: 1s blink step-end infinite;
-ms-animation: 1s blink step-end infinite;
-o-animation: 1s blink step-end infinite;
animation: 1s blink step-end infinite;
background-color: white;
font-size: 1em;
}

@keyframes "blink" {
from, to {
  color: transparent;
}
50% {
  color: black;
}
}

@-moz-keyframes blink {
from, to {
  color: transparent;
}
50% {
  color: black;
}
}

@-webkit-keyframes "blink" {
from, to {
  color: transparent;
}
50% {
  color: black;
}
}

@-ms-keyframes "blink" {
from, to {
  color: transparent;
}
50% {
  color: black;
}
}

@-o-keyframes "blink" {
from, to {
  color: transparent;
}
50% {
  color: black;
}
}
`;

function StartUp({ setUserAuth, setIsLoading, setIsTransition }) {
    const device = DeviceDetail();
    const [command, setCommand] = useState(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            let dstart = { target: { value: "intro" } }
            runCommand(dstart);
        }, 2000);
        return () => clearTimeout(timer);
    }, [command]);

    useEffect(() => {
        // Define the function to be called on key press
        const handleKeyPress = () => {
            updateLoading();
        };

        // Add event listener for any key press
        window.addEventListener('keydown', handleKeyPress);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []); // Empty dependency array means this effect runs only once on mount

    function updateLoading() {
        setIsTransition(true);
    }

    function runCommand(command) {
        setCommand(command.target.value);
    }
    return (
        <>
            <LoginPageOverride />
            {
                device.width < 850 ? (
                    <>
                        <div className="gs-screen-mobile">
                            
                            <div className="gs-image-container">
                                <img style={{ maxWidth: "100%", opacity: ".35" }} src="https://imgs.search.brave.com/FyD19zZEKHCVKNGZST9sQgO7a3CmOC-hsF-IRb33UYc/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9pLnBp/bmltZy5jb20vb3Jp/Z2luYWxzLzdmLzdm/LzI4LzdmN2YyODgy/ODk5NzU1YTcwNWEy/OTUzYjZmY2ZjMjYz/LmdpZg.gif" />
                            </div>
                            <div className="gs-info-mobile">
                                <div className="gs-info-command" style={{ display: "flex" }}>
                                    <p className="gs-info-command-user">user</p>
                                    <p className="gs-info-command-namespace">@future: </p>
                                    <p className="gs-info-command-prompt" style={{ animation: "1.4s linear 1s both animate" }}>
                                        $ {`./launch --intro`}
                                    </p>
                                </div>
                                <div className="gs-info-content">
                                    <h4 style={command ? { marginTop: "0em" } : { display: "none" }}>Welcome to Futurist</h4>
                                    <p style={command ? { display: "block" } : { display: "none" }}>
                                        Futurist is a web application library dedicated to creating expansive user experiences.
                                    </p>
                                    <p style={command ? { display: "block" } : { display: "none" }}>
                                        We provide a framework allowing anyone create virtual desktop applications.
                                    </p>
                                    <p style={command ? { display: "block" } : { display: "none" }}>
                                        If you are interested in expanding this project, feel free to reach out.
                                    </p>
                                </div>
                            </div>
                            <div className="gs-button-container" style={{ display: "grid", padding: "2em", paddingTop: "1em", }}>
                                <button className="button-inverted" style={{ marginTop: "1em" }} onClick={updateLoading}>Click to Continue</button>
                            </div>
                        </div>
                    </>
                )
                    :
                    <>
                            <div className="gs-screen-desktop" style={{ height: "94vh" }}>
                                <div className="gs-screen-left" style={{ height: "100%", display: "flex"}}>
                                <Draggable>
                                    <div className="gs-info-desktop" style={{margin: "auto", maxWidth: "50vw", minWidth: "50vw"}}>
                                        <div className="gs-info-command" style={{ display: "flex" }}>
                                            <p className="gs-info-command-user">root</p>
                                            <p className="gs-info-command-namespace">@future: </p>
                                            <p className="gs-info-command-prompt" style={{ animation: "3s linear 1s both animate" }}>
                                                $ {`./launch --intro`}
                                            </p>
                                        </div>
                                        <div className="gs-info-content">
                                            <h4 style={command ? { marginTop: "0em" } : { display: "none" }}>Welcome to Futurist</h4>
                                            <p style={command ? { display: "block" } : { display: "none" }}>
                                                Futurist is a personal research and development platform.
                                            </p>
                                            <p style={command ? { display: "block" } : { display: "none" }}>
                                                We provide a framework for anyone to train machine learning models using brain data via a supported EEG headset.
                                            </p>
                                            <p style={command ? { display: "block" } : { display: "none" }}>
                                                All trainings are done on-device. All raw data streamed, as well as labeled data from trainings, can be exported.
                                            </p>
                                            <p style={command ? { display: "block" } : { display: "none" }}>
                                                Trainings and data streams can be done entirely offline in a local setting.
                                            </p>
                                            <p style={command ? { display: "block" } : { display: "none" }}>
                                                If you are interested in expanding this project, feel free to reach out.
                                            </p>
                                            <p style={command ? { display: "block" } : { display: "none" }}>
                                                Release notes are viewable by clicking FUTURIST at the top.
                                            </p>
                                            <p style={command ? { display: "block", fontWeight: "800", color: "#02111b", backgroundColor: "#6bf178", padding: ".5em" } : { display: "none" }}>
                                                Press any key to continue...
                                            </p>
                                        </div>
                                    </div>
                                    </Draggable>
                                </div>
                            </div>
                    </>
            }
        </>
    )
}

export default StartUp
