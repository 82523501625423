import { atom } from 'recoil';

export const sessionDetailState = atom({
    key: "sessionDetailState",
    default: {
        isCapturing: false,
        selectedSession: null,
        trainingStatus: -1,
        newSession: {
            meta: {
                name: '',
                description: '',
                hours: 0,
                minutes: 0,
                seconds: 0,
                startTime: null,
                endTime: null,
                type: null
            },
            samples: [],
            notes: []
        },
        sessions: []
    }
});