import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Draggable from "react-draggable";
import DataContext from '../contexts/dataContext'

// Applications
import ExplorerModal from './explorer/Explorer';
import NotepadModal from './notepad/Notepad';
import BrainConnect from './brainconnect/BrainConnect';
import SetupWizard from './setupWizard/SetupWizard';
import ReadmeLaunch from './ReadmeLaunch.js/ReadmeLaunch';
import SuperHighway from './superhighway/SuperHighway';
import ProjectExplorer from './projectexplorer/ProjectExplorer';
import QCF from './quantumcoinflip/QuantumCoinFlip';
import BrainWaveConfig from './brainwaveviewer/BrainWaveConfig';

import { DeviceDetail } from '../recoil/atom/deviceDetail';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { deviceDetailState, windowManipulator } from '../recoil/atom/deviceDetailState';

const StyledShorcut = styled.div`
    padding: 1em;
    align-items: center;
    display: grid;
    text-align: center;
    height: 8em;
    width: 9em;
`;
const iconStyle = {
    display: "flex",
    margin: "auto",
    paddingBottom: ".25em"
};

function Applications({
    headsetClient,
    setHeadsetClient    
}) {

    const device = DeviceDetail();
    const setDeviceDetail = useSetRecoilState(deviceDetailState);
    const manipulateWindows = useSetRecoilState(windowManipulator);
    const [renderedContent, setRenderedContent] = useState(null);

    useEffect(() => {
        const content = (
          <div style={{ top: "0", height: "94vh", width: device.deskSpace.width - 15, marginRight: "-1em" }}>
            {
              device.windows.map(window => {
                const SpecificComponent = windowComponents[window.id];
                return SpecificComponent ? (
                  <SpecificComponent key={window.id} window={window} headsetClient={headsetClient} setHeadsetClient={setHeadsetClient}/>
                ) : null;
              })
            }
          </div>
        );
        setRenderedContent(content);
      }, [device.deskSpace, device.windows, headsetClient]);

    // Mobile handler
    let [dragInfo, setDragInfo] = useState(null);

    const windowComponents = {
        'bwv': BrainWaveConfig,
        'qcf': QCF,
        'bconnect': BrainConnect,
        'setup': SetupWizard,
        'xplorer': ExplorerModal,
        'superhw': ProjectExplorer,
        'intro': ReadmeLaunch
    };    

    const toggleWindow = (windowId) => {
        const currentWindows = device.windows;
        const windowExists = currentWindows.some(w => w.id === windowId);

        console.log("window id receivded for toggle:" + windowId)

        manipulateWindows({
            type: windowExists ? 'remove' : 'add',
            window: {
                id: windowId,
                width: "300px",
                height: "400px",
                zIndex: 99999
            }
        });
    };

    // Mobile clicks
    let handleDragStart = (e, data) => {
        setDragInfo({
            x: data.x,
            y: data.y,
            time: Date.now()
        })
    }
    let handleDragStop = (e, data) => {
        if (!dragInfo) return
        let change = {
            x: Math.abs(data.x - dragInfo.x),
            y: Math.abs(data.y - dragInfo.y),
            time: Date.now() - dragInfo.time
        }
        if (change.x + change.y <= 10 && change.time < 300) {
            e.srcElement.click()
        }
    }

    return (
        <>
            <div style={{ display: "flex", position: "fixed" }}>
                <div style={{ width: "10em" }}>
                    {
                        device.width < 600 ? (
                            <Draggable onStart={handleDragStart} onStop={handleDragStop}>
                                <StyledShorcut onClick={() => toggleWindow('xplorer')}>
                                    <div style={iconStyle}>
                                        <img style={{ height: "4em" }} alt="" src="./icons/folder.png"></img>
                                    </div>
                                    <div className="shortcut-name">Project Documents</div>
                                </StyledShorcut>
                            </Draggable>
                        )
                            :
                            <Draggable>
                                <StyledShorcut onClick={() => toggleWindow('xplorer')}>
                                    <div style={iconStyle}>
                                        <img style={{ height: "4em" }} alt="" src="./icons/folder.png"></img>
                                    </div>
                                    <div className="shortcut-name">Project Documents</div>
                                </StyledShorcut>
                            </Draggable>
                    }
                    {
                        device.width < 600 ? (
                            <Draggable onStart={handleDragStart} onStop={handleDragStop}>
                                <StyledShorcut onClick={() => toggleWindow('superhw')}>
                                    <div style={iconStyle}>
                                        <img style={{ height: "4em", backgroundColor: "#6bf178" }} alt="" src="./icons/web-shortcut.png"></img>
                                    </div>
                                    <div className="shortcut-name">NetXplorer</div>
                                </StyledShorcut>
                            </Draggable>
                        )
                            :
                            <Draggable>
                                <StyledShorcut onClick={() => toggleWindow('superhw')}>
                                    <div style={iconStyle}>
                                        <img style={{ height: "4em", backgroundColor: "#6bf178" }} alt="" src="./icons/web-shortcut.png"></img>
                                    </div>
                                    <div className="shortcut-name">NetXplorer</div>
                                </StyledShorcut>
                            </Draggable>
                    }
                    {
                        device.width < 600 ? (
                            <Draggable onStart={handleDragStart} onStop={handleDragStop}>
                                <StyledShorcut onClick={() => toggleWindow('bconnect')}>
                                    <div style={iconStyle}>
                                        <img style={{ height: "4em" }} alt="" src="./icons/brain.png"></img>
                                    </div>
                                    <div className="shortcut-name">Manage Sessions</div>
                                </StyledShorcut>
                            </Draggable>
                        )
                            :
                            <Draggable>
                                <StyledShorcut onClick={() => toggleWindow('bconnect')}>
                                    <div style={iconStyle}>
                                        <img style={{ height: "4em" }} alt="" src="./icons/brain.png"></img>
                                    </div>
                                    <div className="shortcut-name">Manage Sessions</div>
                                </StyledShorcut>
                            </Draggable>
                    }
                    {
                        device.width < 600 ? (
                            <Draggable onStart={handleDragStart} onStop={handleDragStop}>
                                <StyledShorcut onClick={() => toggleWindow('bwv')}>
                                    <div style={iconStyle}>
                                        <img style={{ height: "4em" }} alt="" src="./icons/mind-training.png"></img>
                                    </div>
                                    <div className="shortcut-name">Session Review</div>
                                </StyledShorcut>
                            </Draggable>

                        )
                            :
                            <Draggable>
                                <StyledShorcut>
                                    <div style={iconStyle}>
                                        <img style={{ height: "4em" }} alt="" src="./icons/mind-training.png"></img>
                                    </div>
                                    <div className="shortcut-name" onClick={() => toggleWindow('bwv')}>Session Review</div>
                                </StyledShorcut>
                            </Draggable>

                    }
                    {
                        device.width < 600 ? (
                            <Draggable onStart={handleDragStart} onStop={handleDragStop}>
                                <StyledShorcut onClick={() => toggleWindow('setup')}>
                                    <div style={iconStyle}>
                                        <img style={{ height: "4em" }} alt="" src="./icons/startup-wizard.png"></img>
                                    </div>
                                    <div className="shortcut-name">Configure Devices</div>
                                </StyledShorcut>
                            </Draggable>

                        )
                            :
                            <Draggable>
                                <StyledShorcut>
                                    <div style={iconStyle}>
                                        <img style={{ height: "4em" }} alt="" src="./icons/startup-wizard.png"></img>
                                    </div>
                                    <div className="shortcut-name" onClick={() => toggleWindow('setup')}>Configure Devices</div>
                                </StyledShorcut>
                            </Draggable>

                    }
                </div>
                <div style={{ width: "10em" }}>
                    {
                        device.width < 600 ? (
                            <Draggable onStart={handleDragStart} onStop={handleDragStop}>
                                <StyledShorcut onClick={() => toggleWindow('qcf')}>
                                    <div style={iconStyle}>
                                        <img style={{ height: "4em" }} alt="" src="./icons/qcf.png"></img>
                                    </div>
                                    <div className="shortcut-name">Quantum Coin Flip</div>
                                </StyledShorcut>
                            </Draggable>

                        )
                            :
                            <Draggable>
                                <StyledShorcut>
                                    <div style={iconStyle}>
                                        <img style={{ height: "4em" }} alt="" src="./icons/qcf.png"></img>
                                    </div>
                                    <div className="shortcut-name" onClick={() => toggleWindow('qcf')}>Quantum Coin Flip</div>
                                </StyledShorcut>
                            </Draggable>

                    }
                </div>
                <div>
                    {renderedContent}
                </div>

            </div>
        </>
    )
}
export default Applications