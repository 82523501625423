import { atom } from 'recoil';
import { osName, osVersion, isMobileSafari, browserName, browserVersion, mobileVendor, mobileModel } from 'react-device-detect';

export const browserDetailState = atom({
    key: "browserDetailState",
    default: {
        is_supported: false,
        osName: osName,
        osVersion: osVersion,
        browserName: browserName,
        browserVersion: browserVersion,
        mobileVendor: mobileVendor,
        mobileModel: mobileModel,
        useAltLogo: isMobileSafari
    }
});