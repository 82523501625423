import React from 'react'
import Draggable, { DraggableCore } from "react-draggable";
import ContentFactory from './NotepadContent/ContentFactory';

import { DeviceDetail } from '../../recoil/atom/deviceDetail';

function NotepadModal({ notepadToggle, selectedItem, renderWindows, windowTop, isMobile }) {

    function bringToFront(window) {
        console.log("Rendering window.", window)
        renderWindows(window);
    }

    let item_id = null;
    if(selectedItem){
        item_id = selectedItem.id;
    }

    const device = DeviceDetail();

    return (
        <Draggable
            handle="strong"
            bounds="parent"
            onStart={bringToFront}>
            <div
                className="modal-window-defaults"
                style={{
                    left: device.width > 850 ? '15%' : '',
                    width: device.appWidth,
                    zIndex: windowTop === selectedItem.id ? 1000 : 999
                }}
            >
                <strong
                    className="title-bar"
                >
                    <span className="modal-title"
                        onClick={bringToFront}
                        id={selectedItem.id}
                        >
                        {selectedItem.name}
                    </span>
                    <div className="title-tray"
                    >
                        <button
                            className="modal-close"
                            onClick={() => notepadToggle(selectedItem)}
                            onTouchStart={() => notepadToggle(selectedItem)}
                        >
                            X
                        </button>
                    </div>
                </strong>
                <ContentFactory id={item_id} isMobile={isMobile} bringToFront={bringToFront}/>
            </div>
        </Draggable>
    )
}

export default NotepadModal
