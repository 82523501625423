export default class DataService {
    _data = {
      items: [
        {
          id: 'about',
          type: 'General',
          name: 'About Futurist',
          icon: 'info_bubble',
          content: {
            paragraphs: [
              'Futurist is a personal framework to capture and study brainwave data from EEG headsets',
              '',
              'By utilizing off-the-shelf hardware, anyone can work with their own brainwaves, without the fear of where the data is sent.',
              '',
              'This project expects to expand in a variety of ways, with the sole purpose of providing more insight into our inner self.'
            ],
          },
        },
        {
          id: 'howitworks',
          type: 'General',
          name: 'How It Works',
          icon: 'notepad_2',
          content: {
            paragraphs: [
              'Futurist utilizes the Web Bluetooth API, typically found in Chromium-based browsers.',
              '',
              'Also needed is a supported headset, a list can be found in the Supported Headsets file.'
            ],
          },
        },
        {
          id: 'supportedheadsets',
          type: 'General',
          name: "Supported Headsets",
          icon: 'notepad_2',
          content: {
            paragraphs: [
              'Futurist works with common, off-the-shelf EEG headsets.',
              '',
              'Only the Muse 2 headset is supported out-of-the-box.',
              '',
              'Additional headsets can be enabled.'
            ],
          },
        },
        {
          id: 'dataprocessing',
          type: 'General',
          name: 'Data Privacy',
          icon: 'notepad_2',
          content: {
            paragraphs: [
              'This site connects to a headset that reads your brain.',
              '',
              'What do you think the implications of someone having that data could be?',
              '',
              'To be honest, there is a (very) high chance you give up more of what you think on social platforms, where the value of your thoughts, connected to your mobile number and / or email address, on a subject are most likely not reciprocated.',
              '',
              'Rest assured, this site does not "collect" or store any information. It is one of the few sites left that do not require cookies.'
            ]
          }
        },
        {
          id: 'whataresessions',
          type: 'Sessions',
          name: 'What Are Sessions',
          icon: 'notepad_2',
          content: {
            paragraphs: [
              'Sessions are timed captures of EEG data.',
              '',
              'They are transferable, being able to be exported and imported across instances',
              '',
              'The export is in JSON format, which is still under standardization.'
            ]
          }
        },
        {
          id: 'createnewsession',
          type: 'Sessions',
          name: 'Creating Sessions',
          icon: 'notepad_2',
          content: {
            paragraphs: [
              'Creating',
            ]
          }
        },
        {
          id: 'editexistingsessions',
          type: 'Sessions',
          name: 'Editing Sessions',
          icon: 'notepad_2',
          content: {
            paragraphs: [
              'Theres a few way to edit sessions'
            ]
          }
        }
      ],
    };
  
    getItems() {
      return this._data.items.map(({ id, type, name, icon }) => ({ id, type, name, icon }));
    }
  
    getItem(id) {
      return this._data.items.find((x) => x.id === id);
    }
  }