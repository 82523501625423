import React, { useEffect, useState } from "react";

import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components'

import DataService from '../services/dataService';
import DataContext from '../contexts/dataContext';
import Taskbar from './Taskbar';
import Login from './Login';
import Desktop from './Desktop';
import StartUp from './StartUp';
import Transition from './Transition';

import { DeviceDetail } from "../recoil/atom/deviceDetail";

const dataService = new DataService();

const GlobalStyles = createGlobalStyle`
  #root {
    display: grid;
    font-family: 'Print Char';
  }
`;

const BodyFontSizeOverride = createGlobalStyle`
  body{
    font-size: .75em;
    margin: .5em;
    background-color: #02111B;
    color: #6BF178;
    background-image: url("");
    border: solid .1em #6BF178;
    padding: 0em;
    min-height: 97vh;
    font-family: 'Print Char';
  }
`;

function App() {
    const size = DeviceDetail();
    const [isBooting, setIsBooting] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isTransition, setIsTransition] = useState(false);
    const [userAuth, setUserAuth] = useState(false);
    const [menuEnabled, toggleMenu] = useState(false);
    
    const [headsetClient, setHeadsetClient] = useState();

    const menuClick = () => {
        toggleMenu(!menuEnabled);
    }

    const toggleAuth = () => {
        console.log("Setting user auth ..", userAuth)
        setUserAuth(!userAuth);
    };

    if (isBooting) {
        return (
            <>
                <Login
                    setIsBooting={setIsBooting}
                />
            </>
        );
    }
    else {
        return (
            <DataContext.Provider value={dataService}>
                <BodyFontSizeOverride />
                <Taskbar
                    login={toggleAuth}
                    menuClick={menuClick}
                    menuEnabled={menuEnabled}
                    headsetClient={headsetClient}
                    setHeadsetClient={setHeadsetClient} />
                {
                    !isBooting && !isTransition && !userAuth &&
                    <>
                        <StartUp
                            setIsLoading={setIsLoading}
                            setIsTransition={setIsTransition}
                        />
                    </>
                }
                {isTransition && !isBooting && !userAuth &&
                    <>
                        <Transition
                            setUserAuth={setUserAuth}
                            setIsTransition={setIsTransition}
                        />
                    </>
                }
                {userAuth && !isTransition &&
                    <>
                        <Desktop
                            login={toggleAuth}
                            headsetClient={headsetClient}
                            setHeadsetClient={setHeadsetClient}/>
                    </>
                }
            </DataContext.Provider>
        );
    }
}

export default App;
