import { selector } from 'recoil';
import { headsetDetailState } from './headsetDetailState';

export const headsetStreamSelector = selector({
    key: 'headsetStreamSelector',
    get: ({get}) => {
        const detail = get(headsetDetailState);
        return detail.headsetStream;
    }
});

export const isConnectedSelector = selector({
    key: 'connectedSelector',
    get: ({get}) => {
        const detail = get(headsetDetailState);
        return detail.isConnected;
    },
    set: ({set, get}, connectedStatus) => {
        console.log("Setting connected status to: " + connectedStatus);

        const detail = get(headsetDetailState);
        set(headsetDetailState, {
            ...detail,
            isConnected: connectedStatus
        })
    }
})

export const isStreamingSelector = selector({
    key: 'streamingSelector',
    get: ({get}) => {
        const detail = get(headsetDetailState);
        return detail.isStreaming;
    },
    set: ({set, get}, streamingStatus) => {
        console.log("Setting streaming status to: " + streamingStatus);

        const detail = get(headsetDetailState);
        set(headsetDetailState, {
            ...detail,
            isStreaming: streamingStatus
        })
    }
})