import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useRecoilState } from 'recoil';
import { headsetDetailState } from '../../../recoil/atom/headsetDetailState';
import { profileDetailState } from '../../../recoil/atom/profileDetailState';
import { headsetStreamSelector, isStreamingSelector } from '../../../recoil/atom/headsetDetailSelector';
import React, { useState, useEffect, createRef } from 'react';

function SetupProfile() {

    const [headsetDetail, setHeadsetDetail] = useRecoilState(headsetDetailState)
    const [profileDetail, setProfileDetail] = useRecoilState(profileDetailState);
    const [selectedProfile, setSelectedProfile] = useState(null);

    useEffect(() => {
        if (profileDetail.profiles.length == 0) {
            // Create first profile

            const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for (let i = 0; i < 7; i++) {
                result += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            
            const new_profile = {
                id: result,
                isSelected: true,
                isCapturing: false,
                sessions: []
            };

            setProfileDetail(
                prevDetails => ({
                    ...prevDetails,
                    profiles: [new_profile]
                })
            )
        } else {
            setSelectedProfile(0)
        }
        console.log(profileDetail);
    }, [profileDetail]);


    const handleCheckToggle = (sub, key, value) => {
        setHeadsetDetail(prevDetails => ({
            ...prevDetails,
            [sub]: { ...prevDetails[sub], [key]: !value }
        }));
        console.log(value);
    }

    const handleInputChange = (sub, key, value) => {
        setHeadsetDetail(prevDetails => ({
            ...prevDetails,
            [sub]: { ...prevDetails[sub], [key]: value }
        }));
        console.log(headsetDetail)
    };

    const generateRandomId = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 7; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
    
        setProfileDetail(prevDetails => {
            const updatedProfiles = [...prevDetails.profiles];
            
            if (selectedProfile !== null && updatedProfiles[selectedProfile]) {
                updatedProfiles[selectedProfile] = {
                    ...updatedProfiles[selectedProfile],
                    id: result
                };
            }
    
            return {
                ...prevDetails,
                profiles: updatedProfiles
            };
        });
    }    
    

    return (
        <>
            <h4
                style={{
                    margin: "auto",
                    textAlign: "center",
                    backgroundColor: "#02111B",
                    color: "#6BF178",
                    marginBottom: ".5em",
                    width: "75%",
                    padding: ".5em",
                    marginTop: "-1.75em",
                    border: "double .25em",
                    lineHeight: "1.2"
                }}
            >
                Create Local ID
            </h4>
            <div style={{padding: ".5em", display: "grid"}}>
                <p style={{textAlign: "center", marginTop: "0em", marginBottom: ".5em"}}>Local IDs are used to associate capture sessions.</p>
                {
                    selectedProfile !== null &&

                    <>
                        <input 
                            style={{textAlign: "center", fontFamily: "Print Char", margin: "auto", width: "50%", marginBottom: ".5em", padding: ".5em", color: "#6bf178", backgroundColor: "rebeccapurple", border: "ridge .25em #6bf178"}}
                            value={profileDetail.profiles[selectedProfile].id}
                            /> 
                        <button className="button-inverted" onClick={generateRandomId}>Generate Random Id</button>
                    </>
                }
            </div>
        </>
    )
}
export default SetupProfile
