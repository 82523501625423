import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { browserDetailState } from "./browserDetailState";
import { osName, osVersion, isMobileSafari, browserName, browserVersion, mobileVendor, mobileModel } from 'react-device-detect';

export const BrowserDetail = () => {
    const [browserDetails, setBrowserDetails] = useRecoilState(browserDetailState)

    useEffect(() => {
        function checkSupportedBrowser() {
            if (browserName === "Chrome" || browserName === "Edge Chromium" || browserName === "Opera") {
                let is_supported = true;
                setBrowserDetails({is_supported, osName, osVersion, isMobileSafari, browserName, browserVersion, mobileVendor, mobileModel});
            }
        }
        checkSupportedBrowser();
    }, [setBrowserDetails]);

    return browserDetails

}