import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Resizable } from 're-resizable';
import Draggable, { DraggableCore } from "react-draggable";

import { useRecoilState, useSetRecoilState } from 'recoil';
import { DeviceDetail } from '../../recoil/atom/deviceDetail';
import { windowManipulator } from '../../recoil/atom/deviceDetailState';

function ReadmeLaunch() {

    const device = DeviceDetail();
    const windowDetails = device.windows.find(w => w.id === "intro");
    const manipulateWindows = useSetRecoilState(windowManipulator);

    const [selectedValue, setSelectedValue] = useState('');
    const [selectedTab, setSelectedTab] = useState("Start");

    const titleBarRef = useRef(null);
    const innerBodyRef = useRef(null);
    const [modalBackgroundHeight, setModalBackgroundHeight] = useState('auto');
    const [modalBackgroundWidth, setModalBackgroundWidth] = useState('auto');
    const [modalInnerHeight, setModalInnerHeight] = useState('auto');

    useEffect(() => {
        if (titleBarRef.current && windowDetails.height) {
            const titleBarHeight = titleBarRef.current.offsetHeight;
            const newHeight = `calc(${windowDetails.height} - ${titleBarHeight}px - 16px)`;
            const newWidth = `calc(${windowDetails.width} - ${titleBarRef.current.offsetWidth})px - 16px`;
            console.log(titleBarHeight);

            setModalBackgroundHeight(newHeight);
            setModalBackgroundWidth(newWidth);

            //
            const innerDivHeight = innerBodyRef.current.offsetHeight;
            const newInnerHeight = `calc(${newHeight} - 25px)`;
            console.log("The new inner height");
            console.log(innerDivHeight);
            setModalInnerHeight(newInnerHeight);
            
            const handleUpdateWindow = () => {
                manipulateWindows({
                    type: 'update',
                    window: {
                        id: windowDetails.id,
                        props: { width: newWidth, height: newHeight }
                    }
                });
            };


        }
    }, [windowDetails]);

    const closeWindow = () => {
        manipulateWindows({
            type: 'remove',
            window: {
                id: windowDetails.id
            }
        })
    }

    const bringToFront = () => {
        manipulateWindows({
            type: 'reindex',
            window: { id: windowDetails.id }
        });
    };

    const handleResize = useCallback((newWidth, newHeight) => {
        manipulateWindows({
            type: 'update',
            window: {
                id: windowDetails.id,
                props: { width: newWidth, height: newHeight}
            }
        });
    }, [manipulateWindows]);

    const onResizeStop = useCallback((e, direction, ref, delta, position) => {
        const newSize = {
            width: ref.style.width,
            height: ref.style.height
        }

        handleResize(newSize.width, newSize.height)
    }, [handleResize])


    const [command, setCommand] = useState(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            let dstart = { target: { value: "intro" } }
            runCommand(dstart);
        }, 2000);
        return () => clearTimeout(timer);
    }, [command]);


    function runCommand(command) {
        setCommand(command.target.value);
    }

    return (
        <>
            {
                windowDetails && 
                    <>
                        <Draggable
                        handle="strong"
                        bounds="parent"
                        onStart={bringToFront}>
                        <div
                            className="modal-window-defaults"
                            style={{
                                zIndex: windowDetails.zIndex,
                            }}
                        >
                            <Resizable 
                                size={{ width: windowDetails.width, height: windowDetails.height }}
                                onResizeStop={onResizeStop}
                                minConstraints={[200, 200]} 
                                maxConstraints={[800, 800]}>
                                <strong className="title-bar" ref={titleBarRef}>
                                    <span className="modal-title" onClick={ bringToFront} id="readme">Introduction // V.8.5</span>
                                    <div className="title-tray">
                                        <button className="modal-close" onClick={() => closeWindow(windowDetails.id)}>X</button>
                                    </div>
                                </strong>
                            <div 
                                className="modal-window-background"
                                ref={innerBodyRef}
                                style={{
                                    backgroundColor: "#6bf178",
                                    padding: ".5em",
                                    height: modalBackgroundHeight,  // Dynamic height
                                    width: modalBackgroundWidth
                                }}>
                                <div 
                                    style={{ 
                                        backgroundColor: "#02111B", 
                                        padding: ".25em", 
                                        border: ".25em inset #6bf178" }}>
            
                                    <div
                                        style={{
                                            padding: ".5em",
                                            height: modalInnerHeight,
                                            backgroundColor: "rebeccapurple",
                                        }}>
                                        <div
                                            style={{
                                                maxHeight: "100%",
                                                border: "ridge .25em #6bf178",
                                                backgroundColor: "#02111b",
                                                overflow: 'auto',
                                                scrollbarWidth: 'none', // For Firefox
                                                msOverflowStyle: 'none',
                                                scrollbarWidth: "none", // Hide the scrollbar for firefox
                                                'div::-webkit-scrollbar': {
                                                    display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                                                },
                                                '&-ms-overflow-style:': {
                                                    display: 'none', // Hide the scrollbar for IE
                                                },
                                            }}>
            
                                                <div className="gs-info-command" style={{ display: "flex" }}>
                                                    <p className="gs-info-command-prompt" style={{ animation: "3s linear 1s both animate" }}>
                                                        $ {`./launch --intro`}
                                                    </p>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </Resizable>
                        </div>
                    </Draggable>
                    </>
                
            }
        </>
    )
}

export default ReadmeLaunch
