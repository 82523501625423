import React, { useState, useContext, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import styled, { createGlobalStyle } from 'styled-components';
import DataContext from '../contexts/dataContext';
import { DeviceDetail } from "../recoil/atom/deviceDetail";
import { desktopResizing } from '../recoil/atom/deviceDetailState';
import Applications from '../applications/apps';

const DesktopPageOverride = createGlobalStyle`
    .desktop {
      height: 100%;
    }

    .desktop-fade {
        animation: fadeIn 5s;
        -webkit-animation: fadeIn 5s;
        -moz-animation: fadeIn 5s;
        -o-animation: fadeIn 5s;
        -ms-animation: fadeIn 5s;
      }
      
      @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }
      
      @-moz-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }
      
      @-webkit-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }
      
      @-o-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }
      
      @-ms-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }
`;

function Desktop({
  login,
  headsetClient,
  setHeadsetClient,
  headsetDataStream,
  setHeadsetDataStream
}) {

  const device = DeviceDetail();
  const data = useContext(DataContext);
  const deskSpace = useRef(null);
  const resizeDesktop = useSetRecoilState(desktopResizing);
  const [menuEnabled, toggleMenu] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [items, setItems] = useState([]);
  const bodyRef = useRef(null);

  const handleResize = () => {
    if (deskSpace.current && bodyRef.current) {
      const dSpaceHeight = bodyRef.current.offsetHeight - deskSpace.current.offsetTop;
      const dSpaceWidth = bodyRef.current.offsetWidth - 7;
      resizeDesktop({
        width: dSpaceWidth, height: dSpaceHeight
      });
    }
  };

  useEffect(() => {
    bodyRef.current = document.body;
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const files = data.getItems();
    setItems(files);
    setSelectedItem(files[0]);
  }, [data]);

  return (
    <>
      <DesktopPageOverride />
      <div className="desktop desktop-fade" ref={deskSpace} style={{ minHeight: "100%" }}>
        <Applications
          login={login}
          headsetClient={headsetClient}
          setHeadsetClient={setHeadsetClient}
        />
      </div>
      { /*
        device.width >= 850 &&
        <>
          <div style={{ position: "fixed", bottom: 0, textAlign: "center", width: "100%", lineHeight: "1.5em" }}>
            <span>read about our digital journey</span>
          </div>
        </>
      */}
    </>
  );
}

export default Desktop;
