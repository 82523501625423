import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useRecoilState } from 'recoil';
import { headsetDetailState } from '../../../recoil/atom/headsetDetailState';
import { headsetStreamSelector, isStreamingSelector } from '../../../recoil/atom/headsetDetailSelector';
import React, { useState, useEffect, createRef } from 'react';

function SetupConfig() {

    const [headsetDetail, setHeadsetDetail] = useRecoilState(headsetDetailState)
    const [selected, setSelected] = useState("Basic Configuration");

    const handleChange = (event) => {
        setSelected(event.target.value);
    }

    const handleCheckToggle = (sub, key, value) => {
        setHeadsetDetail(prevDetails => ({
            ...prevDetails,
            [sub]: { ...prevDetails[sub], [key]: !value }
        }));
        console.log(value);
    }

    const handleInputChange = (sub, key, value) => {
        setHeadsetDetail(prevDetails => ({
            ...prevDetails,
            [sub]: { ...prevDetails[sub], [key]: value }
        }));
        console.log(headsetDetail)
    };

    return (
        <>
            <div style={{margin: ".25em"}}>
                <select 
                    style={{backgroundColor: "#02111B", color: "#6BF178", marginBottom: ".25em", border: "#6bf178 outset .25em", outline: "solid .1em #02111B", fontSize: "1em", padding: ".5em"}}
                    value={selected}
                    onChange={handleChange}
                    >
                    <option>Basic Configuration</option>
                    <option>Notch Filter</option>
                    <option>Lowpass Filter</option>
                    <option>Highpass Filter</option>
                    <option>Bandpass Filter</option>
                    <option>Slice FFT</option>
                </select>
            </div>
            <div className="configuration-container" style={{backgroundColor: "#02111B", padding: ".5em", border: "#6bf178 inset .25em"}}>
                <div style={{border: "ridge #6bf178 .25em", padding: ".25em", backgroundColor: "rebeccapurple"}}>
                {
                    selected == "Basic Configuration" && (
                        <>
                            <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Interval</p>
                                <input 
                                    style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                    value={headsetDetail.settings.interval}
                                    onChange={e => handleInputChange('settings', 'interval', e.target.value)}
                                    />
                            </div>
                            <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Bins</p>
                                <input 
                                    style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                    value={headsetDetail.settings.bins}
                                    onChange={e => handleInputChange('settings', 'bins', e.target.value)}
                                    />
                            </div>
                            <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Duration</p>
                                <input 
                                    style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                    value={headsetDetail.settings.duration}
                                    onChange={e => handleInputChange('settings', 'duration', e.target.value)}
                                    />
                            </div>
                            <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Sample Rate</p>
                                <input 
                                    style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                    value={headsetDetail.settings.srate}
                                    onChange={e => handleInputChange('settings', 'srate', e.target.value)}></input>
                            </div>
                        </>
                    )
                }
                {
                    selected == "Notch Filter" && (
                        <>  
                            <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Status</p>
                                <label
                                    style={{
                                        backgroundColor: "#02111B",
                                        color: headsetDetail.notchFilterSettings.enableNotchFilter ? "#6bf178" : "red",
                                        textAlign: "center",
                                        width: "100%",
                                        border: "ridge #6bf178 .25em",
                                        margin: "auto",
                                        padding: ".5em"
                                    }}
                                    onClick={() => handleCheckToggle('notchFilterSettings', 'enableNotchFilter', headsetDetail.notchFilterSettings.enableNotchFilter)}
                                    >
                                    {headsetDetail.notchFilterSettings.enableNotchFilter ? 'Enabled' : 'Disabled'}
                                </label>
                            </div>
                            {
                                headsetDetail.notchFilterSettings.enableNotchFilter && (
                                    <>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Number of Channels</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.notchFilterSettings.nbChannels}
                                                onChange={e => handleInputChange('notchFilterSettings', 'nbChannels', e.target.value)}
                                                />
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Cut off frequency</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%",  margin: "auto", padding: ".5em",  fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.notchFilterSettings.cutoffFrequency}
                                                onChange={e => handleInputChange('notchFilterSettings', 'cutoffFrequency', e.target.value)}
                                                />
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Bandwidth</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.notchFilterSettings.bandWidth}
                                                onChange={e => handleInputChange('notchFilterSettings', 'bandWidth', e.target.value)}
                                                />
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Use Harmonics</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em",  fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.notchFilterSettings.filterHarmonics}
                                                onChange={e => handleInputChange('notchFilterSettings', 'filterHarmonics', e.target.value)}
                                                />
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "45% 55%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Method</p>
                                            <select
                                                style={{margin: "auto", padding: ".5em", fontSize: "1em", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.notchFilterSettings.characteristic}
                                                onChange={e => handleInputChange('notchFilterSettings', 'characteristic', e.target.value)}
                                                >
                                                <option>butterworth</option>
                                                <option>bessel</option>
                                            </select>
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Order</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.notchFilterSettings.order}
                                                onChange={e => handleInputChange('notchFilterSettings', 'order', e.target.value)}
                                                />
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
                }
                {
                    selected == "Lowpass Filter" && (
                        <>
                            <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Status</p>
                                <label
                                    style={{
                                        backgroundColor: "#02111B",
                                        color: headsetDetail.lowPassFilterSettings.enableLowPassFilter ? "#6bf178" : "red",
                                        textAlign: "center",
                                        width: "100%",
                                        border: "ridge #6bf178 .25em",
                                        margin: "auto",
                                        padding: ".5em"
                                    }}
                                    onClick={() => handleCheckToggle('lowPassFilterSettings', 'enableLowPassFilter', headsetDetail.lowPassFilterSettings.enableLowPassFilter)}
                                    >
                                    {headsetDetail.lowPassFilterSettings.enableLowPassFilter ? 'Enabled' : 'Disabled'}
                                </label>
                            </div>
                            {
                                headsetDetail.lowPassFilterSettings.enableLowPassFilter && (
                                    <>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Number of Channels</p>
                                            <input
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.lowPassFilterSettings.nbChannels}
                                                onChange={e => handleInputChange('lowPassFilterSettings', 'nbChannels', e.target.value)}
                                                />
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Cut off frequency</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.lowPassFilterSettings.cutoffFrequency}
                                                onChange={e => handleInputChange('lowPassFilterSettings', 'cutoffFrequency', e.target.value)}
                                                />
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Sample Rate</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.lowPassFilterSettings.samplingRate}
                                                onChange={e => handleInputChange('lowPassFilterSettings', 'samplingRate', e.target.value)}
                                                />
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "45% 55%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Method</p>
                                            <select
                                                style={{fontSize: "1em", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.lowPassFilterSettings.characteristic}
                                                onChange={e => handleInputChange('lowPassFilterSetting', 'characteristic', e.target.value)}
                                                >
                                                    <option>butterworth</option>
                                                    <option>bessel</option>
                                            </select>
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Order</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.lowPassFilterSettings.order}
                                                onChange={e => handleInputChange('lowPassFilterSettings', 'order', e.target.value)}
                                                />
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
                }
                {
                    selected == "Highpass Filter" && (
                        <>
                            <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Status</p>
                                <label
                                    style={{
                                        backgroundColor: "#02111B",
                                        color: headsetDetail.highPassFilterSettings.enableHighPassFilter ? "#6bf178" : "red",
                                        textAlign: "center",
                                        width: "100%",
                                        border: "ridge .25em #6bf178",
                                        margin: "auto",
                                        padding: ".5em"
                                    }}
                                    onClick={() => handleCheckToggle('highPassFilterSettings', 'enableHighPassFilter', headsetDetail.highPassFilterSettings.enableHighPassFilter)}
                                    >
                                    {headsetDetail.highPassFilterSettings.enableHighPassFilter ? 'Enabled' : 'Disabled'}
                                </label>
                            </div>
                            {
                                headsetDetail.highPassFilterSettings.enableHighPassFilter && (
                                    <>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Number of Channels</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.highPassFilterSettings.nbChannels}
                                                onChange={e => handleInputChange('highPassFilterSettings', 'nbChannels', e.target.value)}
                                                />
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Cut off frequency</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.highPassFilterSettings.cutoffFrequency}
                                                onChange={e => handleInputChange('highPassFilterSettings', 'cutoffFrequency', e.target.value)}
                                                />
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Sample Rate</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.highPassFilterSettings.samplingRate}
                                                onChange={e => handleInputChange('highPassFilterSettings', 'samplingRate', e.target.value)}
                                                />
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "45% 55%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Method</p>
                                            <select
                                                style={{fontSize: "1em", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.highPassFilterSettings.characteristic}
                                                onChange={e => handleInputChange('highPassFilterSettings', 'characteristic', e.target.value)}
                                                >
                                                    <option>butterworth</option>
                                                    <option>bessel</option>
                                            </select>
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Order</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.highPassFilterSettings.order}
                                                onChange={e => handleInputChange('highPassFilterSettings', 'order', e.target.value)}
                                                />
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
                }
                {
                    selected == "Bandpass Filter" && (
                        <>
                            <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Status</p>
                                <label
                                    style={{
                                        backgroundColor: "#02111B",
                                        color: headsetDetail.bandPassFilterSettings.enableBandPassFilter ? "#6bf178" : "red",
                                        textAlign: "center",
                                        width: "100%",
                                        border: "ridge .25em #6bf178",
                                        margin: "auto",
                                        padding: ".5em"
                                    }}
                                    onClick={() => handleCheckToggle('bandPassFilterSettings', 'enableBandPassFilter', headsetDetail.bandPassFilterSettings.enableBandPassFilter)}
                                    >
                                    {headsetDetail.bandPassFilterSettings.enableBandPassFilter ? 'Enabled' : 'Disabled'}
                                </label>
                            </div>
                            {
                                headsetDetail.bandPassFilterSettings.enableBandPassFilter && (
                                    <>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Number of Channels</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.bandPassFilterSettings.nbChannels}
                                                onChange={e => handleInputChange('bandPassFilterSettings', 'nbChannels', e.target.value)}
                                                />
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Cut Off (low)</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.bandPassFilterSettings.lowCutOff}
                                                onChange={e => handleInputChange('bandPassFilterSettings', 'lowCutOff', e.target.value)}
                                                />
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Cut Off (high)</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.bandPassFilterSettings.highCutOff}
                                                onChange={e => handleInputChange('bandPassFilterSettings', 'highCutOff', e.target.value)}
                                                />
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto",fontWeight: "800", textAlign: "center"}}>Sample Rate</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.bandPassFilterSettings.samplingRate}
                                                onChange={e => handleInputChange('bandPassFilterSettings', 'samplingRate', e.target.value)}
                                                />
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "45% 55%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>MethoFilterd</p>
                                            <select
                                                style={{fontSize: "1em", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.bandPassFilterSettings.characteristic}
                                                onChange={e => handleInputChange('bandPassFilterSettings', 'characteristic', e.target.value)}
                                                >
                                                    <option>butterworth</option>
                                                    <option>bessel</option>
                                            </select>
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Order</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.bandPassFilterSettings.order}
                                                onChange={e => handleInputChange('bandPassFilterSettings', 'order', e.target.value)}
                                                />
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
                }
                {
                    selected == "Slice FFT" && (
                        <>
                            <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Status</p>
                                <label
                                    style={{
                                        backgroundColor: "#02111B",
                                        color: headsetDetail.sliceFFTSettings.enableSliceFFT ? "#6bf178" : "red",
                                        textAlign: "center",
                                        width: "100%",
                                        border: "ridge .25em #6bf178",
                                        margin: "auto",
                                        padding: ".5em"
                                    }}
                                    onClick={() => handleCheckToggle('sliceFFTSettings', 'enableSliceFFT', headsetDetail.sliceFFTSettings.enableSliceFFT)}
                                    >
                                    {headsetDetail.sliceFFTSettings.enableSliceFFT ? 'Enabled' : 'Disabled'}
                                </label>
                            </div>
                            {
                                headsetDetail.sliceFFTSettings.enableSliceFFT && (
                                    <>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Slice FFT (Min)</p>
                                            <input 
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.sliceFFTSettings.minFFT}
                                                onChange={e => handleInputChange('sliceFFTSettings', 'minFFT', e.target.value)}
                                                />
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "60% 30%", padding: ".25em"}}>
                                            <p style={{margin: "auto", fontWeight: "800", textAlign: "center"}}>Slice FFT (Max)</p>
                                            <input
                                                style={{textAlign: "center", width: "100%", margin: "auto", padding: ".5em", fontFamily: "Print Char", color: "#6bf178", backgroundColor: "#02111B", border: "ridge .25em #6bf178"}}
                                                value={headsetDetail.sliceFFTSettings.maxFFT}
                                                onChange={e => handleInputChange('sliceFFTSettings', 'maxFFT', e.target.value)}
                                                />
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
                }
                </div>
            </div>
            
        </>
    )
}
export default SetupConfig
