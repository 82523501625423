import React, { useEffect, useRef } from 'react';

function Transition({ setUserAuth, setIsLoading, isLoading, setIsTransition, screenSize }) {

    let textburn = useRef();

    useEffect(() => {
        setTimeout(loadingDone, 1000);
        makeTransition();
    })

    function loadingDone() {
        setIsTransition(false);
        setUserAuth(true);
    }

    function makeTransition() {
        // Initialising the canvas
        var canvas = textburn;
        let ctx = canvas.current.getContext('2d');

        // Setting the width and height of the canvas
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        // Setting up the letters
        var letters = 'ABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZ';
        letters = letters.split('');

        // Setting up the columns
        let columns = canvas.width / 4;

        // Setting up the drops
        var drops = [];
        for (var i = 0; i < columns * 10; i++) {
            drops[i] = 1;
        }

        // Setting up the draw function
        function draw() {
            ctx.fillStyle = 'rgba(2, 17, 27, .1)';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            for (var i = 0; i < drops.length; i++) {
                var text = letters[Math.floor(Math.random() * letters.length)];
                ctx.fillStyle = '#6BF178';
                ctx.font = "5px monospace";
                ctx.lineHeight = "1em";
                ctx.fillText(text, i * 4, drops[i] * 4);
                drops[i]++;
            }
        }

        // Loop the animation
        setInterval(draw, 20);
    }

    return (
        <>
            <div>
                <canvas style={{ width: "100%", height: "94vh", display: "block" }} id="transition" ref={textburn}>
                </canvas>
            </div>
        </>
    )
}
export default Transition;