import React, { useState, useEffect } from 'react';
import EyeBlinks from './training/EyeBlinks';
import EyeWinks from './training/EyeWinks';
function BrainGestures({
    headsetClient
}) {
    const [selectedGesture, setSelectedGesture] = useState("eyes_blinks");

    function changeScreen(e) {
        setSelectedGesture(e.target.value)
    }

    return (
        <>
            <select
                style={{
                    display: "block",
                    margin: "auto",
                    backgroundColor: "#02111B",
                    color: "#6bf178",
                    border: "medium outset #6bf178",
                    padding: ".5em",
                    fontSize: "1em",
                    outline: "#02111b solid .1em"
                }}
                onChange={changeScreen}
            >
                <option value="eyes_blinks">Eyes / Blinks</option>
                <option value="eyes_winks">Eyes/ Winks</option>
                <option value="mouth_smiles">Mouth / Smiles</option>
                <option value="mouth_frowns">Mouth / Frowns</option>
            </select>
            <div 
                style={{ 
                    backgroundColor: "#02111b", 
                    margin: ".5em .25em .25em",
                    padding: ".25em", 
                    border: ".25em inset #6bf178" 
                    }
                    }>
                <div
                    style={{backgroundColor: "rebeccapurple", padding: ".5em"}}
                >
                    <div 
                        style={{ 
                            textAlign: "center", 
                            backgroundColor: "#02111b",
                            border: ".25em ridge #6bf178",
                            padding: ".5em"
                        }}>

                        {
                            selectedGesture === "eyes_blinks" &&
                            <>
                                <EyeBlinks headsetClient={headsetClient} />
                            </>
                        }
                        {
                            selectedGesture === "eyes_winks" &&
                            <>
                                <EyeWinks />
                            </>
                        }
                        {
                            selectedGesture === "mouth_smiles" &&
                            <>
                            </>
                        }
                        {
                            selectedGesture === "mouth_frowns" &&
                            <>
                            </>
                        }
                        {
                            selectedGesture === "supported_browsers" &&
                            <>
                                <p>This is currently only available in Chromium-based browsers (excluding Brave), such as:</p>
                                <p>
                                    - Chrome <br />
                                    - Microsoft Edge <br />
                                    - Opera <br />
                                    - Chromium <br />
                                    - Samsung Browser <br />
                                </p>
                                <p>
                                    On Linux platforms, web bluetooth must be enabled within the browser.
                                </p>
                                <p>
                                    This is available on Android <strong>but not iOS</strong>.
                                </p>

                            </>
                        }
                        {
                            selectedGesture === "data_privacy" &&
                            <>
                                <p>This site connects to a headset that quite literally reads your brain.</p>
                                <p>What do you think the implications of someone having that data could be?</p>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default BrainGestures
