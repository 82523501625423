import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import Draggable, { DraggableCore } from "react-draggable";

const FilesWrapper = styled.div`
	display: flex;
	grid-template-columns: repeat(4, 1fr);
	flex-wrap: wrap;
`;

const inputContainerStyle = {
  display: "flex",
  backgroundColor: "rgba(0,0,0,0)"
}

const inputStyle = {
  backgroundColor: "rgba(0,0,0,0)",
  color: "#6BF178",
  border: "none",
  width: "100%",
  outline: "none"
}

function IntroModal({ introModalToggle, setUserAuth, setIsLoading, isLoading, screenSize, isMobile }) {

  const [inputQuery, setInputQuery] = React.useState("");
  const loginInput = useRef(null);
  useEffect(() => {
    if (loginInput.current) {
      selectInput();
    }
    if (inputQuery.includes("-")) {
      console.log(".. listen..")
    }
    if (inputQuery.toLowerCase() == "login") {
      startLoad()

    }
  }, [inputQuery]);

  function selectInput() {
    loginInput.current.focus();
  }

  function updateInput(e) {
    setInputQuery(e.target.value);
  }

  function startLoad() {
    setIsLoading(!isLoading);
  }

  return (
    <div style={{ position: "fixed", top: "1em", height: "90vh", width: screenSize.width - 10 }}>
      <Draggable
        handle="strong"
        bounds="parent">
        <div
          className="printchar modal-window-defaults "
          style={{
            left: screenSize.width > 850 ? '15%' : '5%',
            width: screenSize.width > 850 ? '50em' : "90%"
          }}>
          <div
          >
            <strong
              className="title-bar"
            >
              <span className="modal-title">Futurist Login</span>
            </strong>
            <div className="modal-window-content">
              <div className="message-container" style={{ width: "100%" }}>
                <div className="message-data">
                  <div style={{ display: "grid" }}>
                    <span>-----------------------</span>
                    <span>futurist concept v0.1.0</span>
                    <span>-----------------------</span>
                  </div>
                  <div>
                    <p style={{ borderBottom: ".2em dashed", paddingBottom: "1em" }}>
                      {`> This project focuses on neural research in combination with blockchain connectivity.`}<br /><br />
                      {`> Get started by typing 'login' in the terminal below:`}
                    </p>
                    <div style={{display: "flex"}}>
                      <span style={{width: "100%", maxWidth: "12em"}}>root@ftrst: $ </span><input className="printchar" style={inputStyle} autoFocus ref={loginInput} onChange={updateInput}></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    </div>
  )
}

export default IntroModal
