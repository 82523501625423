import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import Draggable, { DraggableCore } from "react-draggable";
import { Resizable } from 're-resizable';

import { useRecoilState, useSetRecoilState } from 'recoil';
import { headsetDetailState } from '../../recoil/atom/headsetDetailState';
import { windowManipulator } from '../../recoil/atom/deviceDetailState';
import { DeviceDetail } from '../../recoil/atom/deviceDetail';
import { BrowserDetail } from '../../recoil/atom/browserDetail';

import SetupIntro from './extras/SetupIntro';
import SetupChecklist from './extras/SetupChecklist';
import SetupConnect from './extras/SetupConnect';
import SetupStream from './extras/SetupStream';
import SetupConfig from './extras/SetupConfig';
import SetupProfile from './extras/SetupProfile';

function SetupWizard({
    headsetClient,
    setHeadsetClient
}) {


    const device = DeviceDetail();
    const browser = BrowserDetail();
    const windowDetails = device.windows.find(w => w.id === "setup");
    const manipulateWindows = useSetRecoilState(windowManipulator);
    
    const [screen, setScreen] = useState("intro");
    const [selectedTab, setSelectedTab] = useState("Connect");
    const [headsetDetail, setHeadsetDetail] = useRecoilState(headsetDetailState);

    const titleBarRef = useRef(null);
    const innerBodyRef = useRef(null);
    const [modalBackgroundHeight, setModalBackgroundHeight] = useState('auto');
    const [modalBackgroundWidth, setModalBackgroundWidth] = useState('auto');
    const [modalInnerHeight, setModalInnerHeight] = useState('auto');

    useEffect(() => {
        if (titleBarRef.current && windowDetails.height) {
            const titleBarHeight = titleBarRef.current.offsetHeight;
            const newHeight = `calc(${windowDetails.height} - ${titleBarHeight}px - 16px)`;
            const newWidth = `calc(${windowDetails.width} - ${titleBarRef.current.offsetWidth})px - 16px`;
            console.log(titleBarHeight);

            setModalBackgroundHeight(newHeight);
            setModalBackgroundWidth(newWidth);

            //
            const innerDivHeight = innerBodyRef.current.offsetHeight;
            const newInnerHeight = `calc(${newHeight} - 25px)`;
            console.log("The new inner height");
            console.log(innerDivHeight);
            setModalInnerHeight(newInnerHeight);
            
            const handleUpdateWindow = () => {
                manipulateWindows({
                    type: 'update',
                    window: {
                        id: windowDetails.id,
                        props: { width: newWidth, height: newHeight }
                    }
                });
            };


        }
    }, [windowDetails]);

    const closeWindow = () => {
        manipulateWindows({
            type: 'remove',
            window: {
                id: windowDetails.id
            }
        })
    }

    const bringToFront =() => {
        manipulateWindows({
            type: 'reindex',
            window: { id: windowDetails.id }
        });
    };

    const handleResize = useCallback((newWidth, newHeight) => {
        manipulateWindows({
            type: 'update',
            window: {
                id: windowDetails.id,
                props: { width: newWidth, height: newHeight}
            }
        });
    }, [manipulateWindows]);

    const onResizeStop = useCallback((e, direction, ref, delta, position) => {
        const newSize = {
            width: ref.style.width,
            height: ref.style.height
        }

        handleResize(newSize.width, newSize.height)
    }, [handleResize])

    function tabSelection(e) {
        setSelectedTab(e.target.textContent);
    }

    function changeScreen(e) {
        // Set screen from button event
        setScreen(e.target.value);
    }

    return (
        <>
            {
                windowDetails &&
                <>
                    <Draggable
                    handle="strong"
                    bounds="parent"
                    onStart={bringToFront}>
                    <div
                        className="modal-window-defaults"
                        style={{
                            left: device.width > 850 ? '15%' : '',
                            zIndex: windowDetails.zIndex,
                        }}
                    >
                        <Resizable 
                            size={{ width: windowDetails.width, height: windowDetails.height }}
                            onResizeStop={onResizeStop}
                            minConstraints={[200, 200]} 
                            maxConstraints={[device.width - 100, device.height - 100]}>
                                <strong className="title-bar" ref={titleBarRef}>
                            <span
                                className="modal-title"
                                onClick={bringToFront}
                                id="helper">Configure Devices</span>
                            <div className="title-tray"
                            >
                                <button
                                    className="modal-close"
                                    onClick={() => closeWindow(windowDetails.id)}
                                    onTouchStart={() => closeWindow(windowDetails.id)}
                                >
                                    X
                                </button>
                            </div>
                        </strong>

                        <div
                            className="modal-window-background"
                        >
                            <div className="message-container" style={{ width: "100%" }} ref={innerBodyRef}>
                                <div className="tabs">
                                    <button className={selectedTab === "Connect" ? 'tab-selected' : 'tab'} onClick={tabSelection}>Connect</button>
                                    <button className={selectedTab === "Configure" ? 'tab-selected' : 'tab'} onClick={tabSelection}>Configure</button>
                                </div>
                                <div className="tab-container">
                                    {
                                        selectedTab == "Connect" && (
                                            <>
                                                <div 
                                                    style={{ 
                                                        backgroundColor: "#02111B", 
                                                        margin: ".5em 0.25em 0.25em", 
                                                        padding: ".25em", 
                                                        border: ".25em inset #6bf178" }}>

                                                    <div
                                                        style={{
                                                            padding: ".5em",
                                                            backgroundColor: "rebeccapurple"
                                                        }}>
                                                        <div
                                                            style={{
                                                                border: "ridge .25em #6bf178",
                                                                backgroundColor: "#02111b",
                                                            }}>
                                                        {
                                                            screen === "intro" &&
                                                            <>
                                                                <SetupIntro setScreen={setScreen} />
                                                            </>
                                                        }
                                                        {
                                                            screen === "profile" &&
                                                            <>
                                                                <SetupProfile setScreen={setScreen} />
                                                            </>
                                                        }
                                                        {
                                                            screen === "connect" &&
                                                            <>
                                                                <SetupConnect
                                                                    setScreen={setScreen}
                                                                    headsetClient={headsetClient}
                                                                    setHeadsetClient={setHeadsetClient}
                                                                    browserSupported={browser.is_supported}
                                                                />
                                                            </>
                                                        }
                                                        {
                                                            screen === "stream" &&
                                                            <>
                                                                <SetupStream
                                                                    setScreen={setScreen}
                                                                    headsetClient={headsetClient}
                                                                    setHeadsetClient={setHeadsetClient}
                                                                    browserSupported={browser.is_supported}
                                                                />
                                                            </>
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            <div style={{ display: "flex", marginTop: ".5em" }}>
                                                {
                                                    screen === "intro" &&
                                                    <>
                                                        <button className="button" style={{width: "100%"}} value="profile" onClick={changeScreen}>
                                                            {`Continue ->`}
                                                        </button>
                                                    </>
                                                }
                                                {
                                                    screen === "profile" &&
                                                    <>
                                                        <button className="button" style={{ width: "45%", margin: "auto" }} value="intro" onClick={changeScreen}>
                                                            {`<- Back`}
                                                        </button>
                                                        <button className="button-inverted" style={{ width: "45%", margin: "auto" }} value="connect" onClick={changeScreen}>
                                                            {`Next ->`}
                                                        </button>
                                                    </>
                                                }
                                                {
                                                    screen === "connect" &&
                                                    <>
                                                        {
                                                            headsetDetail.isConnected ? (
                                                                <> 
                                                                    <button className="button" style={{ width: "45%", margin: "auto" }} value="profile" onClick={changeScreen}>
                                                                        {`<- Back`}
                                                                    </button>
                                                                    <button className="button-inverted" style={{ width: "45%", margin: "auto" }} value="stream" onClick={changeScreen}>
                                                                        {`Next ->`}
                                                                    </button>
                                                                </>
                                                            )
                                                            :
                                                                <button className="button" style={{ width: "100%", margin: "auto" }} value="profile" onClick={changeScreen}>
                                                                {`<- Back`}
                                                            </button>
                                                        }
                                                    </>
                                                }
                                                {
                                                    screen === "stream" &&
                                                    <>
                                                        <button className="button" style={{ width: "100%" }} value="connect" onClick={changeScreen}>
                                                            {`<- Back`}
                                                        </button>
                                                    </>
                                                }
                                            </div>
                                            </>
                                        )
                                    }
                                    {
                                        selectedTab === "Configure" &&
                                        <>
                                            <SetupConfig />  
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        </Resizable>
                    </div>
                </Draggable>
                </>
            }
        </>
    )
}

export default SetupWizard
