import React, { useState } from 'react';

function EyeWinks({ setScreen }) {
    const [isTraining, setIsTraining] = useState(false);
    const [trainingType, setTrainingType] = useState("open");

    function changeScreen(e) {
        console.log(e);
        setTrainingType(e.target.value)
    }

    function toggleTraining() {
        setIsTraining(!isTraining);
    }

    return (
        <>
            {
                trainingType === "open" &&
                <>
                    <p>OPEN EYE TRAINING</p>
                    <hr style={{ border: ".15em dashed" }} />
                    <p>Capture EEG data with the left eye open and right eye closed for 10 seconds.</p>
                    <div style={{ display: "block" }}>
                        {
                            !isTraining ? (
                                <>
                                    <button className="button" onClick={toggleTraining}>Start Training</button>
                                </>
                            )
                                :
                                <>
                                    <button className="button" onClick={toggleTraining}>Pause Training</button>
                                    <div>Capturing .. </div>
                                </>
                        }

                    </div>
                    <button className="button-inverted" value="closed" style={{ width: "100%" }} onClick={changeScreen}>Next Step -&gt;</button>
                </>
            }
            {
                trainingType === "closed" &&
                <>
                    <p>CLOSED EYE TRAINING</p>
                    <hr style={{ border: ".15em dashed" }} />
                    <p>Capture EEG data with the left eye closed and the right eye open for 10 seconds.</p>
                    <div style={{ display: "block" }}>
                        {
                            !isTraining ? (
                                <>
                                    <button className="button" onClick={toggleTraining}>Start Training</button>
                                </>
                            )
                                :
                                <>
                                    <button className="button" onClick={toggleTraining}>Pause Training</button>
                                    <div>Capturing .. </div>
                                </>
                        }

                    </div>
                    <div>
                        <button className="button-inverted" value="open" style={{ width: "100%" }} onClick={changeScreen}>Back Step -&gt;</button>
                        <button className="button-inverted" value="complete" style={{ width: "100%" }}>Run Training -&gt;</button>
                    </div>
                </>
            }
        </>
    )
}
export default EyeWinks
