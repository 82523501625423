import { useState } from 'react';
import { headsetDetailState } from '../../recoil/atom/headsetDetailState';
import { browserDetailState } from '../../recoil/atom/browserDetailState';
import { useRecoilState, useRecoilValue } from 'recoil';

export default function TrainingChecklist() {
    const [headsetDetail, setHeadsetDetail] = useRecoilState(headsetDetailState);
    const browserDetail = useRecoilValue(browserDetailState);
    return (
        <>
            <div style={{
                display: "grid",
                gridTemplateColumns: "80% 20%",
                padding: ".5em"
            }}>
                <span style={{ margin: "auto", padding: ".5em", textAlign: "center" }}>
                    Supported Browser
                </span>
                {
                    browserDetail.is_supported ? (
                        <>
                            <span style={{ backgroundColor: "#6BF178", color: "#02111b", padding: ".5em", textAlign: "center", border: "#6BF178 .25em ridge" }}>
                                YES
                            </span>
                        </>
                    )
                        :
                        <>
                            <span style={{ backgroundColor: "red", color: "#02111b", padding: ".5em", textAlign: "center", border: "red .25em ridge" }}>
                                NO
                            </span>
                        </>
                }
            </div>
            <div style={{
                display: "grid",
                gridTemplateColumns: "80% 20%",
                padding: ".5em"
            }}>
                <span style={{ margin: "auto", padding: ".5em", textAlign: "center" }}>
                    Headset Connected
                </span>
                {
                    headsetDetail.isConnected ? (
                        <>
                            <span style={{ backgroundColor: "#6BF178", color: "#02111b", padding: ".5em", textAlign: "center", border: "#6BF178 .25em ridge" }}>
                                YES
                            </span>
                        </>
                    )
                        :
                        <>
                            <span style={{ backgroundColor: "red", color: "#02111b", padding: ".5em", textAlign: "center", border: "red .25em ridge" }}>
                                NO
                            </span>
                        </>
                }
            </div>
            <div style={{
                display: "grid",
                gridTemplateColumns: "80% 20%",
                padding: ".5em"
            }}>
                <span style={{ margin: "auto", padding: ".5em", textAlign: "center" }}>
                    Headset Streaming
                </span>
                {
                    headsetDetail.isStreaming ? (
                        <>
                            <span style={{ backgroundColor: "#6BF178", color: "#02111b", padding: ".5em", textAlign: "center", border: "#6BF178 .25em ridge" }}>
                                YES
                            </span>
                        </>
                    )
                        :
                        <>
                            <span style={{ backgroundColor: "red", color: "#02111b", padding: ".5em", textAlign: "center", border: "red .25em ridge" }}>
                                NO
                            </span>
                        </>
                }
            </div>
        </>
    )
}