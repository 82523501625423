import React, { useState, useEffect, useRef, useCallback } from 'react'
import Draggable from "react-draggable";
import { Resizable } from 're-resizable';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { headsetStreamSelector } from '../../recoil/atom/headsetDetailSelector';
import { isCapturingSelector } from '../../recoil/atom/modelDetailSelector';
import { DeviceDetail } from '../../recoil/atom/deviceDetail';
import { headsetDetailState } from '../../recoil/atom/headsetDetailState';
import {sessionDetailState} from '../../recoil/atom/sessionDetailState';
import { windowManipulator } from '../../recoil/atom/deviceDetailState';

import BrainIntro from './files/BrainIntro';
import BrainGestures from './files/BrainGestures';
import BrainData from './files/BrainData';

import CreateSession from './files/CreateSession';
import EditSession from './files/EditSessions';
import ImportSession from './files/ImportSession';
import ExportSession from './files/ExportSession';

function BrainConnect({
    headsetClient,
}) {


    console.log(headsetClient);
    const device = DeviceDetail();
    const windowDetails = device.windows.find(w => w.id === "bconnect");
    const manipulateWindows = useSetRecoilState(windowManipulator);

    const [selectedTab, setSelectedTab] = useState("New");
    const [selectedScreen, setSelectedScreen] = useState('Details');
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [headsetDetail, setHeadsetDetail] = useRecoilState(headsetDetailState)
    const [sessionDetails, setSessionDetails] = useRecoilState(sessionDetailState);
    const [captureStart, setCaptureStart] = useState(null);

    const [captureComplete, setCaptureComplete] = useState(false);
    const headsetStream = useRecoilValue(headsetStreamSelector);
    const headsetStreamRef = useRef(headsetStream);

    const [isCapturing, setIsCapturing] = useRecoilState(isCapturingSelector);

    const fileInputRef = useRef(null);


    const titleBarRef = useRef(null);
    const innerBodyRef = useRef(null);
    const [modalBackgroundHeight, setModalBackgroundHeight] = useState('auto');
    const [modalBackgroundWidth, setModalBackgroundWidth] = useState('auto');
    const [modalInnerHeight, setModalInnerHeight] = useState('auto');

    useEffect(() => {
        if (titleBarRef.current && windowDetails.height) {
            const titleBarHeight = titleBarRef.current.offsetHeight;
            const newHeight = `calc(${windowDetails.height} - ${titleBarHeight}px - 16px)`;
            const newWidth = `calc(${windowDetails.width} - ${titleBarRef.current.offsetWidth})px - 16px`;
            console.log(titleBarHeight);

            setModalBackgroundHeight(newHeight);
            setModalBackgroundWidth(newWidth);

            //
            const innerDivHeight = innerBodyRef.current.offsetHeight;
            const newInnerHeight = `calc(${newHeight} - 25px)`;
            console.log("The new inner height");
            console.log(innerDivHeight);
            setModalInnerHeight(newInnerHeight);
            
            const handleUpdateWindow = () => {
                manipulateWindows({
                    type: 'update',
                    window: {
                        id: windowDetails.id,
                        props: { width: newWidth, height: newHeight }
                    }
                });
            };


        }
    }, [windowDetails]);

    const expandWindow = () => {
        console.log("Expanding to: "  + device.deskSpace.width + " width and " + device.deskSpace.height + " height")
        manipulateWindows({
            type: 'update',
            window: {
                id: windowDetails.id,
                props: {
                    width: device.deskSpace.width,
                    height: device.deskSpace.height
                }
            }
        })
    }

    const closeWindow = () => {
        manipulateWindows({
            type: 'remove',
            window: {
                id: windowDetails.id
            }
        })
    }

    const bringToFront = () => {
        manipulateWindows({
            type: 'reindex',
            window: { id: windowDetails.id }
        });
    };

    const handleResize = useCallback((newWidth, newHeight) => {
        manipulateWindows({
            type: 'update',
            window: {
                id: windowDetails.id,
                props: { width: newWidth, height: newHeight}
            }
        });
    }, [manipulateWindows]);

    const onResizeStop = useCallback((e, direction, ref, delta, position) => {
        const newSize = {
            width: ref.style.width,
            height: ref.style.height
        }

        if(parseInt(newSize.width) > (device.width - 20)) {
            console.log("maxiing size")
            newSize.width = `${device.width - 20}px`;
        }

        if(parseInt(newSize.height) > (device.height - 55)) {
            console.log("Height ad")
            console.log(newSize.height)
            console.log(device.height)
            newSize.height = `${device.height - 55}px`;
        }
    
        handleResize(newSize.width, newSize.height)            
        

    }, [handleResize])


    function tabSelection(e) {
        setSelectedTab(e.target.textContent);
        setSessionDetails( prevDetails => ({
            ...prevDetails,
            selectedSession: null
        }))
    }

    function changeScreen(e) {
        setSelectedScreen(e.target.value)
    }

    useEffect(() => {
        headsetStreamRef.current = headsetStream;
    }, [headsetStream]);

    useEffect(() => {
        let intervalId;

        if(sessionDetails.isCapturing) {

            console.log(sessionDetails)

            //headsetClient.injectMarker("Starting Training Capture");
            intervalId = setInterval(() => {
                if ((Date.now() / 1000 - sessionDetails.newSession.meta.endTime) > 0) {                    

                    clearInterval(intervalId);
                    console.log("Completed eeg capture");

                    const newSamples = headsetStreamRef.current.slice(captureStart, headsetStreamRef.current.length - 1);
                    
                    setSessionDetails(prevDetails => ({
                        ...prevDetails,
                        newSession: {
                            ...prevDetails.newSession,
                            samples: [ ...prevDetails.newSession.samples, ...newSamples]
                        }
                    }));
                    console.log(newSamples);

                    extractSample(newSamples);
                    headsetClient.injectMarker("Ending Training Capture");

                    setCaptureStart(null);

                    console.log(sessionDetails)

                    setSessionDetails(prevDetails => {
                        const updatedSession = {
                            ...prevDetails.newSession,
                            samples: [...prevDetails.newSession.samples, ...newSamples]
                        };

                        const session = {
                            meta: {
                                name: updatedSession.meta.name,
                                description: updatedSession.meta.description,
                                startTime: updatedSession.meta.startTime,
                                endTime: updatedSession.meta.endTime,
                                duration: {
                                    hours: updatedSession.meta.hours,
                                    minutes: updatedSession.meta.minutes,
                                    seconds: updatedSession.meta.seconds
                                },
                                type: null
                            },
                            samples: updatedSession.samples,
                            notes: updatedSession.notes
                        }

                        return {
                            ...prevDetails,
                            sessions: [...prevDetails.sessions, session]
                        }
                    })
                    setCaptureComplete(true);
                } else {
                    console.log("Capturing.. ", Math.floor(Date.now()) / 1000 - (headsetStream[captureStart].info.startTime / 1000))
                }
            }, 1000);
    
        }

        return () => {
            clearInterval(intervalId);
        }
    }, [sessionDetails.isCapturing]);

    function extractSample(eegSample) {
        console.log("Now extracting the sample");
        let eegState = 0;
        
        const newSamples = eegSample.map(sample => ({
            timestamp: sample.info.startTime,
            eegReadings: sample.psd,
            state: eegState
        }));

    }

    function toggleCapture() {
        console.log(sessionDetails)
        if(!captureStart){
            setCaptureStart(headsetStream.length - 1);
        }
        //setIsCapturing(true);
        let capture_start = Math.floor(Date.now()) / 1000;
        console.log("Start time is:", capture_start);
        console.log(sessionDetails.newSession);
        let capture_end = Math.floor(capture_start + (sessionDetails.newSession.meta.hours * 60 * 60 * 1000) + (sessionDetails.newSession.meta.minutes * 60 * 1000) + (sessionDetails.newSession.meta.seconds * 1000) / 1000);
        console.log("End time is:", capture_end);
        setSessionDetails(prevDetails => ({
            ...prevDetails,
            newSession: {
                ...prevDetails.newSession,
                meta: { 
                    ...prevDetails.newSession.meta, 
                    startTime: capture_start,
                    endTime: capture_end
                }
            }
        }));
        
        setSessionDetails(prevDetails => ({
            ...prevDetails,
            isCapturing: true
        }));
    }

    const handleBrowseClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        if (!event.target.files || event.target.files.length === 0) {
            console.error("No file selected");
            return;
        }

        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const fileContent = e.target.result;
            try {
                const parsedData = JSON.parse(fileContent);
                console.log("Looks like this:", parsedData);
                setSessionDetails(prevDetails => {
                    const updatedSessions = [...prevDetails.sessions, parsedData];
                    return {
                        ...prevDetails,
                        sessions: updatedSessions,
                        selectedSession: updatedSessions.length - 1
                    };
                })
                // You can use Recoil or any state management to update the state here
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
        };

        reader.readAsText(file);
    };

    function toggleExport(){
        // Convert to extracting specific sample
        console.log("starting export");
        console.log(sessionDetails)
        
        if(sessionDetails.selectedSession){
            // Export training samples
            const timestamp = Math.floor(Date.now() / 1000);    

            const dataString = JSON.stringify(sessionDetails.sessions[sessionDetails.selectedSession]);
            const blob = new Blob([dataString], {type: 'application/json'});
            const downloadLink = URL.createObjectURL(blob);
            
            const extractDownload = document.createElement('a');
            extractDownload.href = downloadLink;
            extractDownload.download = `eeg_${timestamp}_${sessionDetails.sessions[sessionDetails.selectedSession].samples.length}.json`;
            extractDownload.style.display = 'none';
            
            document.body.appendChild(extractDownload);
            extractDownload.click();
            
            document.body.removeChild(extractDownload);
            URL.revokeObjectURL(downloadLink);
        }

    }

    return (
        <>
            {
                windowDetails &&
                <>
                    <Draggable
                        handle="strong"
                        bounds="parent"
                        onStart={bringToFront}>
                        <div
                            className="modal-window-defaults"
                            style={{
                                left: device.width > 850 ? '15%' : '',
                                zIndex: windowDetails.zIndex,
                                fontSize: device.width > 850 ? "1.1em" : "1em"
                            }}
                        >
                            <Resizable 
                                size={{ width: windowDetails.width, height: windowDetails.height }}
                                onResizeStop={onResizeStop}
                                minConstraints={[200, 200]} 
                                maxConstraints={[device.width - 100, device.height - 100]}>
                                <strong className="title-bar" ref={titleBarRef}>
                                <button
                                        className="modal-close"
                                        style={{backgroundColor: 'blue', color: '#6bf178', marginRight: 0, marginLeft: "1vh"}}
                                        onClick={() => expandWindow(windowDetails.id)}
                                        onTouchStart={() => expandWindow(windowDetails.id)}
                                    >
                                        O
                                    </button>
                                <span
                                    className="modal-title"
                                    onClick={bringToFront}
                                    id="bconnect"
                                >Manage Sessions</span>
                                <div className="title-tray"
                                >
                                    <button
                                        className="modal-close"
                                        onClick={() => closeWindow(windowDetails.id)}
                                        onTouchStart={() => closeWindow(windowDetails.id)}
                                    >
                                        X
                                    </button>
                                </div>
                            </strong>
                            <div
                                className="modal-window-background"
                                onClick={bringToFront}
                                id="bconnect"
                                style={{
                                    backgroundColor: "rebeccapurple",
                                    padding: ".5em",
                                    height: modalBackgroundHeight,  // Dynamic height
                                    width: modalBackgroundWidth
                                }}
                            >
                                <div className="message-container" style={{ width: "100%" }} ref={innerBodyRef}>
                                    <div className="tabs">
                                        <button className={selectedTab === "New" ? 'tab-selected' : 'tab'} onClick={tabSelection}>New</button>
                                        <button className={selectedTab === "Edit" ? 'tab-selected' : 'tab'} onClick={tabSelection}>Edit</button>
                                        <button className={selectedTab === "Import" ? 'tab-selected' : 'tab'} onClick={tabSelection}>Import</button>
                                        <button className={selectedTab === "Export" ? 'tab-selected' : 'tab'} onClick={tabSelection}>Export</button>
                                    </div>
                                    <div className="tab-container">
                                    <div 
                                        style={{ 
                                            backgroundColor: "#02111B", 
                                            margin: ".5em 0.25em 0.25em", 
                                            padding: ".25em", 
                                            border: ".25em inset #6bf178" }}>

                                        <div
                                            style={{
                                                padding: ".5em",
                                                backgroundColor: "rebeccapurple"
                                            }}>
                                                <div style={{
                                                    border: "ridge .25em #6bf178",
                                                    padding: ".5em",
                                                    backgroundColor: "#02111B",
                                                    textAlign: "center"
                                                }}>
                                                    {
                                                        selectedTab === "New" &&
                                                        <>
                                                            <CreateSession selectedScreen={selectedScreen}/>
                                                        </>
                                                    }
                                                    {
                                                        selectedTab === "Edit" &&
                                                        <>
                                                            <EditSession />
                                                        </>
                                                    }
                                                    {
                                                        selectedTab === "Import" &&
                                                        <>
                                                            <ImportSession onFIleChange={handleFileChange} />
                                                            <input 
                                                                type="file"
                                                                accept=".json"
                                                                onChange={handleFileChange}
                                                                ref={fileInputRef}
                                                                style={{display: "none"}}
                                                            />
                                                        </>
                                                    }
                                                    {
                                                        selectedTab === "Export" &&
                                                        <>
                                                            <ExportSession />
                                                        </>
                                                    }
                                                </div>
                                                {
                                                selectedTab === "New" && captureComplete &&
                                                <>
                                                    <p style={{marginBottom: 0, textAlign: "center"}}>Captured {sessionDetails.newSession.samples.length} samples</p>
                                                </>
                                            }
                                            </div>
                                        </div>
                                        {
                                            selectedTab === "New" &&
                                            <>
                                                {
                                                    headsetDetail.isConnected ? (
                                                        <>
                                                        {
                                                            headsetDetail.isStreaming ? (
                                                                <>
                                                                    {
                                                                        selectedScreen === "Details" &&
                                                                        <>
                                                                            <button onClick={changeScreen} className="button" style={{ width: "100%" }} value="Start" >
                                                                                {`Next Step ->`}
                                                                            </button>
                                                                        </>
                                                                    }
                                                                    {
                                                                        selectedScreen === "Start" &&
                                                                        <>
                                                                            <div>
                                                                                <button className="button" style={{ width: "100%" }} value="connect" >
                                                                                    {`<- Previous Step`}
                                                                                </button>
                                                                                <button className="button" style={{ width: "100%" }} onClick={toggleCapture} >
                                                                                    {`Start Session`}
                                                                                </button>
                                                                                
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </>
                                                            )
                                                            :
                                                            <button className="button" style={{display: "block", width: "100%", backgroundColor: "lightgrey", color: "red", border: "outset red .25em"}}  value="connect" >
                                                                {`Not Currently Streaming`}
                                                            </button>
                                                        }
                                                        </>
                                                    )
                                                    :
                                                    <button onClick={changeScreen} className="button" style={{display: "block", width: "100%", backgroundColor: "lightgrey", color: "red", border: "outset red .25em"}}  value="Start" >
                                                        {`No Headset Connected`}
                                                    </button>
                                                }
                                            </>
                                        }
                                        {
                                            selectedTab === "Edit" &&
                                            <>
                                                {
                                                    sessionDetails.sessions.length > 0 ? (
                                                        <>
                                                            {
                                                                sessionDetails.selectedSession ? (
                                                                    <>
                                                                        <button className="button" style={{ width: "100%" }} value="connect" >
                                                                            {`Editing Session`}
                                                                        </button>
                                                                    </>
                                                                )
                                                                :
                                                                <>
                                                                    <button className="button" style={{ width: "100%" }} value="connect" >
                                                                        {`No Session Selected`}
                                                                    </button>
                                                                </>
                                                            }
                                                        </>
                                                    )
                                                    :
                                                    <>
                                                        <button className="button" style={{display: "block", width: "100%", backgroundColor: "lightgrey", color: "red", border: "outset red .25em"}}  value="connect" >
                                                            {`No Sessions Found`}
                                                        </button>
                                                    </>
                                                }
                                            </>
                                        }
                                        {
                                            selectedTab === "Import" &&
                                            <>
                                                <button className="button" style={{ width: "100%" }} onClick={handleBrowseClick}>
                                                    {`Browse for Session`}
                                                </button>
                                            </>
                                        }
                                        {
                                            selectedTab === "Export" &&
                                            <>
                                                {
                                                    sessionDetails.sessions.length > 0 ? (
                                                        <>
                                                            {
                                                                sessionDetails.selectedSession ? (
                                                                    <>
                                                                        <button className="button" style={{ width: "100%" }} onClick={toggleExport} >
                                                                            {`Export Session`}
                                                                        </button>
                                                                    </>
                                                                )
                                                                :
                                                                <>
                                                                    <button className="button" style={{ width: "100%" }} >
                                                                        {`Select A Session`}
                                                                    </button>
                                                                </>
                                                            }
                                                        </>
                                                    )
                                                    :
                                                    <>
                                                        <button className="button" style={{display: "block", width: "100%", backgroundColor: "lightgrey", color: "red", border: "outset red .25em"}}>
                                                            {`No Sessions Found`}
                                                        </button>
                                                    </>
                                                }
                                            </>
                                        }
                                        {
                                            selectedTab === "About" &&
                                            <>
                                                <BrainIntro />
                                            </>
                                        }
                                        {
                                            selectedTab === "Captures" &&
                                            <>
                                                <BrainGestures headsetClient={headsetClient} />
                                            </>
                                        }
                                        {
                                            selectedTab === "Trainings" &&
                                            <>
                                                <BrainData />
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            </Resizable>
                        </div>
                    </Draggable >
                </>
            }
        </>
    )
}

export default BrainConnect