import { selector } from 'recoil';
import { modelDetailState } from './modelDetailState';

export const trainingSelector = selector({
    key: 'trainingSelector',
    get: ({get}) => {
        const detail = get(modelDetailState);
        return detail.trainingSample;
    },
    set: ({set, get}, newSamples) => {
        console.log('New samples being added:', newSamples);

        const detail = get(modelDetailState);
        set(modelDetailState, {
            ...detail,
            trainingSample: [...detail.trainingSample, ...newSamples]
        });
    }
});

export const isCapturingSelector = selector({
    key: 'captureSelector',
    get: ({get}) => {
        const detail = get(modelDetailState);
        return detail.isCapturing;
    },
    set: ({set, get}, captureStatus) => {
        console.log("Setting capture status to: " + captureStatus);

        const detail = get(modelDetailState);
        set(modelDetailState, {
            ...detail,
            isCapturing: captureStatus
        })
    }
})

export const isTrainingSelector = selector({
    key: 'isTrainingSelector',
    get: ({get}) => {
        const detail = get(modelDetailState);
        return detail.isTraining;
    },
    set: ({set, get}, trainingStatus) => {
        console.log("Setting training status to: " + trainingStatus);

        const detail = get(modelDetailState);
        set(modelDetailState, {
            ...detail,
            isTraining: trainingStatus
        })
    }
})

export const trainingStatusSelector = selector({
    key: 'trainingStatus',
    get: ({get}) => {
        const detail = get(modelDetailState);
        return detail.trainingStatus;
    },
    set: ({set, get}, currentStatus) => {
        console.log("Setting training status to: " + currentStatus);

        const detail = get(modelDetailState);
        set(modelDetailState, {
            ...detail,
            trainingStatus: currentStatus
        })
    }
})