import React, { useState, useEffect, useRef, useCallback } from 'react'
import Draggable from "react-draggable";
import { Resizable } from 're-resizable';

import { useRecoilState, useSetRecoilState } from 'recoil';
import { DeviceDetail } from '../../recoil/atom/deviceDetail';
import { windowManipulator } from '../../recoil/atom/deviceDetailState';
import RandomText from './files/RandomText';

function QCF() {

    const device = DeviceDetail();
    const windowDetails = device.windows.find(w => w.id === "qcf");
    const manipulateWindows = useSetRecoilState(windowManipulator);

    const [selectedValue, setSelectedValue] = useState('');
    const [selectedTab, setSelectedTab] = useState("Start");

    const titleBarRef = useRef(null);
    const innerBodyRef = useRef(null);
    const [modalBackgroundHeight, setModalBackgroundHeight] = useState('auto');
    const [modalBackgroundWidth, setModalBackgroundWidth] = useState('auto');
    const [modalInnerHeight, setModalInnerHeight] = useState('auto');

    useEffect(() => {
        if (titleBarRef.current && windowDetails.height) {
            const titleBarHeight = titleBarRef.current.offsetHeight;
            const newHeight = `calc(${windowDetails.height} - ${titleBarHeight}px - 16px)`;
            const newWidth = `calc(${windowDetails.width} - ${titleBarRef.current.offsetWidth})px - 16px`;
            console.log(titleBarHeight);

            setModalBackgroundHeight(newHeight);
            setModalBackgroundWidth(newWidth);

            //
            const innerDivHeight = innerBodyRef.current.offsetHeight;
            const newInnerHeight = `calc(${newHeight} - 25px)`;
            console.log("The new inner height");
            console.log(innerDivHeight);
            setModalInnerHeight(newInnerHeight);
            
            const handleUpdateWindow = () => {
                manipulateWindows({
                    type: 'update',
                    window: {
                        id: windowDetails.id,
                        props: { width: newWidth, height: newHeight }
                    }
                });
            };


        }

        console.log("Did desktop set?")
        console.log(device)
        console.log(device)
    }, [windowDetails]);

    const closeWindow = () => {
        manipulateWindows({
            type: 'remove',
            window: {
                id: windowDetails.id
            }
        })
    }

    const bringToFront = () => {
        manipulateWindows({
            type: 'reindex',
            window: { id: windowDetails.id }
        });
    };

    const expandWindow = () => {
        manipulateWindows({
            type: 'update',
            window: {
                id: windowDetails.id,
                props: {
                    width: device.deskSpace.width,
                    height: device.deskSpace.height
                }
            }
        })
    }

    const handleResize = useCallback((newWidth, newHeight) => {
        manipulateWindows({
            type: 'update',
            window: {
                id: windowDetails.id,
                props: { width: newWidth, height: newHeight}
            }
        });
    }, [manipulateWindows]);

    const onResizeStop = useCallback((e, direction, ref, delta, position) => {
        const newSize = {
            width: ref.style.width,
            height: ref.style.height
        }

        if(parseInt(newSize.width) > (device.width - 20)) {
            console.log("maxiing size")
            newSize.width = `${device.width - 20}px`;
        }

        if(parseInt(newSize.height) > (device.height - 55)) {
            console.log("Height ad")
            console.log(newSize.height)
            console.log(device.height)
            newSize.height = `${device.height - 55}px`;
        }
    
        handleResize(newSize.width, newSize.height)            
        

    }, [handleResize])


    function tabSelection(e) {
        if(e.target.textContent === "About") {
            setSelectedValue('');
        }
        setSelectedTab(e.target.textContent);
    }

    async function flipAgain() {
        setSelectedValue('');
        await quantumCollect();
    }

    async function quantumCollect() {
        try {
            let url = "https://futurist.io/quantum";
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error!`)
            }

            const data = await response.json();
            console.log("Response data:", data)

            const series = data.data;
            
            if(!Array.isArray(series)) {
                throw new Error('Response did not include an array.')
            }

            const sum = series.reduce((acc, num) => acc + num, 0);

            const sumOf = sum % 2 === 0 ? 'heads' : 'tails';

            console.log("The sum of things is:", sumOf);

            if(sumOf == "heads") {
                setSelectedValue('heads');
            } else if (sumOf == "tails") {
                setSelectedValue('tails');
            } else {
                setSelectedValue('');
            }

            return ;
        } catch (error) {
            console.error("Fetch error:", error);
            return 'Error'
        }
    }

    return (
        <>
            {
                windowDetails &&
                <>
                    <Draggable
                        handle="strong"
                        bounds="parent"
                        onStart={bringToFront}>
                        <div
                            className="modal-window-defaults"
                            style={{
                                left: 0,
                                zIndex: windowDetails.zIndex,
                                fontSize: device.width > 850 ? "1.1em" : "1em",
                                top: 0
                            }}
                        >
                            <Resizable 
                                size={{ width: windowDetails.width, height: windowDetails.height }}
                                onResizeStop={onResizeStop}
                                minConstraints={[200, 200]} 
                                maxConstraints={[device.deskSpace.width, device.deskSpace.height]}>
                                <strong className="title-bar" ref={titleBarRef}>
                                <button
                                        className="modal-close"
                                        style={{backgroundColor: 'blue', color: '#6bf178', marginRight: 0, marginLeft: "1vh"}}
                                        onClick={() => expandWindow(windowDetails.id)}
                                        onTouchStart={() => expandWindow(windowDetails.id)}
                                    >
                                        O
                                    </button>
                                <span
                                    className="modal-title"
                                    onClick={bringToFront}
                                    id="qcf">
                                        Quantum Coin Flip</span>
                                <div className="title-tray"
                                >
                                    <button
                                        className="modal-close"
                                        onClick={() => closeWindow(windowDetails.id)}
                                        onTouchStart={() => closeWindow(windowDetails.id)}
                                    >
                                        X
                                    </button>
                                </div>
                            </strong>
                            <div
                                className="modal-window-background"
                                style={{
                                    backgroundColor: "rebeccapurple",
                                    padding: ".5em",
                                    height: modalBackgroundHeight,  // Dynamic height
                                    width: modalBackgroundWidth
                                }}
                            >
                                <div className="message-container" style={{ width: "100%" }} ref={innerBodyRef}>
                                    <div className="tabs">

                                    <button className={selectedTab === "Start" ? 'tab-selected' : 'tab'} onClick={tabSelection}>Start</button>
                                        <button className={selectedTab === "About" ? 'tab-selected' : 'tab'} onClick={tabSelection}>About</button>
                                    </div>
                                    <div className="tab-container">
                                        {
                                            selectedTab == "Start" && (
                                                <>
                                                    {
                                                        selectedValue ? (
                                                            <>
                                                                <button className="button" style={{width: "100%", margin: "auto", display: "block"}} onClick={flipAgain}>Flip Again</button> 
                                                                <div 
                                                                    style={{ 
                                                                        backgroundColor: "#02111B", 
                                                                        margin: ".5em 0.25em 0.25em", 
                                                                        padding: ".25em", 
                                                                        border: ".25em inset #6bf178" 
                                                                    }}>

                                                                    <div
                                                                        style={{
                                                                            padding: ".5em",
                                                                            backgroundColor: "rebeccapurple"
                                                                        }}>
                                                                        <div
                                                                            style={{
                                                                                border: "ridge .25em #6bf178",
                                                                                backgroundColor: "#02111b",
                                                                                padding: ".5em",
                                                                                color: "#6bf178"
                                                                            }}>
                                                                        {
                                                                            (selectedTab === "Start" && selectedValue) &&
                                                                            <>
                                                                                <div>
                                                                                    {
                                                                                        !selectedValue == '' && (
                                                                                            <RandomText choice={selectedValue} />
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) 
                                                        :
                                                        <>
                                                            <div 
                                                                style={{ 
                                                                    backgroundColor: "#02111B", 
                                                                    margin: ".5em 0.25em 0.25em", 
                                                                    padding: ".25em", 
                                                                    border: ".25em inset #6bf178" 
                                                                }}>

                                                                <div
                                                                    style={{
                                                                        padding: ".5em",
                                                                        backgroundColor: "rebeccapurple"
                                                                    }}>
                                                                    <div
                                                                        style={{
                                                                            border: "ridge .25em #6bf178",
                                                                            backgroundColor: "#02111b",
                                                                            padding: ".5em",
                                                                            color: "#6bf178"
                                                                        }}>
                                                                            <h4
                                                                                style={{
                                                                                    margin: "auto",
                                                                                    textAlign: "center",
                                                                                    backgroundColor: "#02111B",
                                                                                    color: "#6BF178",
                                                                                    marginBottom: ".5em",
                                                                                    width: "75%",
                                                                                    padding: ".5em",
                                                                                    marginTop: "-2.25em",
                                                                                    border: "double .25em",
                                                                                    lineHeight: "1.2"
                                                                                }}
                                                                            >
                                                                                Flip to Decide
                                                                            </h4>
                                                                            <p>Ever have a mental fork in the road? Not sure of which path to take?</p>
                                                                            <p>Try Quantum Coin Flip, a truly randomized decision maker.</p>
                                                                            <p>Simply think, "heads I'll do this, tails I'll do that", and flip!</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button className="button" style={{width: "100%", margin: "auto", display: "block"}} onClick={quantumCollect}>Flip a Coin</button>

                                                        </>
                                                    }
                                                </>
                                            )
                                        }
                                        {
                                            selectedTab === "About" && (
                                                <>
                                                    <div 
                                                        style={{ 
                                                            backgroundColor: "#02111B", 
                                                            margin: ".5em 0.25em 0.25em", 
                                                            padding: ".25em", 
                                                            border: ".25em inset #6bf178" }}>

                                                        <div
                                                            style={{
                                                                padding: ".5em",
                                                                backgroundColor: "rebeccapurple"
                                                            }}>
                                                            <div
                                                                style={{
                                                                    border: "ridge .25em #6bf178",
                                                                    backgroundColor: "#02111b",
                                                                    padding: ".5em",
                                                                    color: "#6bf178",
                                                                    maxHeight: "30em"
                                                                }}>
                                                                    <h4
                                                                        style={{
                                                                            margin: "auto",
                                                                            textAlign: "center",
                                                                            backgroundColor: "#02111B",
                                                                            color: "#6BF178",
                                                                            marginBottom: ".5em",
                                                                            width: "75%",
                                                                            padding: ".5em",
                                                                            marginTop: "-2.25em",
                                                                            border: "double .25em",
                                                                            lineHeight: "1.2"
                                                                        }}
                                                                    >
                                                                        Why Use Quantum?
                                                                    </h4>
                                                                <div style={{maxHeight: "20em", overflowY: "scroll"}}>
                                                                    <p>Did you know the weight distribution of a US quarter favors the saying "tails never fails"?</p>
                                                                    <a href="https://www.ripleys.com/weird-news/coin-toss-or-not/"><p>Or how physicists have trained themselves to consistently flip the same result 10 times in a row?</p></a>
                                                                    <p>In the case of asking the universe for a recommendation, randomness is key, and neither of those seem very random.</p>
                                                                    <p>QCF is an app which creates a binary output (heads or tails) based on the sum of numbers.</p>
                                                                    <p>The numbers are generated through a theoretical concept of quantum physics, rather than a typical Quantum Random Number Generator.</p>
                                                                </div>
                                                            </div>
                                                        </div>            
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            </Resizable>
                        </div>
                    </Draggable >
                </>
            }
        </>
    )
}

export default QCF