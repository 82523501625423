/*
In-progress conversion to recoil from redux
*/

import { atom } from 'recoil';

export const modelDetailState = atom({
    key: "modelDetailState",
    default: {
        isCapturing: false,
        isTraining: false,
        trainingStatus: -1,
        trainingSample: [],
        sessions: [],
        modelAccuracy: null,
        completedTrainings: null
    }
});