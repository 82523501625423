import React, { useState, useContext, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import Draggable from "react-draggable";
import { Resizable } from 're-resizable';

import { useRecoilState, useSetRecoilState } from 'recoil';
import { DeviceDetail } from '../../recoil/atom/deviceDetail';
import { windowManipulator } from '../../recoil/atom/deviceDetailState';

import Weblink from './files/WebShortcut';
import WebContent from './files/WebContent';
function ProjectExplorer() {
    
    // Explorer Files
    const [links, setLinks] = useState([]);
    const [selectedLink, setSelectedLink] = useState("");

    useEffect(
        () => {
            const contentLinks = [
                {
                    name: "GeoCities Online",
                    url: "https://geocities.restorativland.org/"
                }
            ];
            setLinks(contentLinks);
        }, []);

    const device = DeviceDetail();
    const windowDetails = device.windows.find(w => w.id === "superhw");
    const manipulateWindows = useSetRecoilState(windowManipulator);

    const [selectedValue, setSelectedValue] = useState('');
    const [selectedTab, setSelectedTab] = useState("Sites");
    const [selectedItem, setSelectedItem] = useState(null);

    const titleBarRef = useRef(null);
    const innerBodyRef = useRef(null);
    const [modalBackgroundHeight, setModalBackgroundHeight] = useState('auto');
    const [modalBackgroundWidth, setModalBackgroundWidth] = useState('auto');
    const [modalInnerHeight, setModalInnerHeight] = useState('auto');

    useEffect(() => {
        if (titleBarRef.current && windowDetails.height) {
            const titleBarHeight = titleBarRef.current.offsetHeight;
            const newHeight = `calc(${windowDetails.height} - ${titleBarHeight}px - 16px)`;
            const newWidth = `calc(${windowDetails.width} - ${titleBarRef.current.offsetWidth})px - 16px`;
            console.log(titleBarHeight);

            setModalBackgroundHeight(newHeight);
            setModalBackgroundWidth(newWidth);

            //
            const innerDivHeight = innerBodyRef.current.offsetHeight;
            const newInnerHeight = `calc(${newHeight} - 25px)`;
            console.log("The new inner height");
            console.log(innerDivHeight);
            setModalInnerHeight(newInnerHeight);
            
            const handleUpdateWindow = () => {
                manipulateWindows({
                    type: 'update',
                    window: {
                        id: windowDetails.id,
                        props: { width: newWidth, height: newHeight }
                    }
                });
            };
        }
    }, [windowDetails]);

    const expandWindow = () => {
        manipulateWindows({
            type: 'update',
            window: {
                id: windowDetails.id,
                props: {
                    width: device.deskSpace.width,
                    height: device.deskSpace.height
                }
            }
        })
    }

    const closeWindow = () => {
        manipulateWindows({
            type: 'remove',
            window: {
                id: windowDetails.id
            }
        })
    }

    const bringToFront = () => {
        manipulateWindows({
            type: 'reindex',
            window: { id: windowDetails.id }
        });
    };

    const handleResize = useCallback((newWidth, newHeight) => {
        manipulateWindows({
            type: 'update',
            window: {
                id: windowDetails.id,
                props: { width: newWidth, height: newHeight}
            }
        });
    }, [manipulateWindows]);

    const onResizeStop = useCallback((e, direction, ref, delta, position) => {
        const newSize = {
            width: ref.style.width,
            height: ref.style.height
        }

        if(parseInt(newSize.width) > (device.width - 20)) {
            newSize.width = `${device.width - 20}px`;
        }

        if(parseInt(newSize.height) > (device.height - 55)) {
            newSize.height = `${device.height - 55}px`;
        }
    
        handleResize(newSize.width, newSize.height)            
        

    }, [handleResize])

    function tabSelection(e) {
        setSelectedTab(e.target.textContent);
    }

    function linkSelection(index) {
        setSelectedLink(links[index].url);
    }

    function clearLink() {
        setSelectedLink(null);
    }

    function renderContent() {
        return links.map((link, index) => (
            <Weblink 
                name={link.name}
                index={index}
                linkSelection={linkSelection}
                />
        ));
    }

    return (
        <>
            {
                windowDetails &&
                <>
                    <Draggable
                        handle="strong"
                        bounds="parent"
                        onStart={bringToFront}>
                        <div
                            className="modal-window-defaults"
                            style={{
                                left: device.width > 850 ? '15%' : '',
                                zIndex: windowDetails.zIndex
                            }}
                        >
                            <Resizable 
                                size={{ width: windowDetails.width, height: windowDetails.height }}
                                onResizeStop={onResizeStop}
                                minConstraints={[200, 200]} 
                                maxConstraints={[device.width - 100, device.height - 100]}>
                                <strong className="title-bar" ref={titleBarRef}>
                                <button
                                        className="modal-close"
                                        style={{backgroundColor: 'blue', color: '#6bf178', marginRight: 0, marginLeft: "1vh"}}
                                        onClick={() => expandWindow(windowDetails.id)}
                                        onTouchStart={() => expandWindow(windowDetails.id)}
                                    >
                                        O
                                    </button>
                                <span
                                    className="modal-title"
                                    onClick={bringToFront}
                                    id="superhw"
                                >NetXplorer</span>
                                <div className="title-tray"
                                >
                                    <button
                                        className="modal-close"
                                        onClick={() => closeWindow(windowDetails.id)}
                                        onTouchStart={() => closeWindow(windowDetails.id)}>
                                        X
                                    </button>
                                </div>
                            </strong>
                            <div 
                                className="modal-window-content"
                                onClick={bringToFront}
                                ref={innerBodyRef}
                                id="superhw">
                                <div
                                className="modal-window-background"
                                style={{
                                    height: modalBackgroundHeight,  // Dynamic height
                                    width: modalBackgroundWidth}}>
                                    {
                                        selectedLink ? (
                                            <>
                                                    <div className="modal-window-container" style={{marginTop: 0, overflow: "scroll", color: "#6bf178"}}>
                                                        <WebContent selectedLink={selectedLink} clearLink={clearLink} />
                                                    </div>
                                            </>
                                        )
                                        :
                                        <>
                                            <div style={{ display: "flow", gridTemplateColumns: "repeat(2,50%)", backgroundColor: "#6bf178", color: "#02111b" }}>
                                                {renderContent()}
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            </Resizable>
                        </div>
                    </Draggable>
                </>
            }
        </>
    )
}

export default ProjectExplorer