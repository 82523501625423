import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useRecoilState } from 'recoil';
import { headsetDetailState } from '../../../recoil/atom/headsetDetailState';
import { headsetStreamSelector, isStreamingSelector } from '../../../recoil/atom/headsetDetailSelector';
import React, { useState, useEffect, createRef } from 'react';
import {
    epoch,
    fft,
    sliceFFT,
    lowpassFilter,
    highpassFilter,
    notchFilter,
    bandpassFilter,
    powerByBand
} from '@neurosity/pipes';
import { MuseClient, zipSamples } from 'muse-js';

function SetupStream({
    headsetClient,
    setHeadsetClient
}) {

    const [headsetState, setHeadsetState] = useRecoilState(headsetDetailState)
    let streamType = "Raw EEG Stream";
    if(headsetState.enablePowerByBand) {
        streamType = "Power by Band"
    }
    const [selected, setSelected] = useState(streamType);
    const [isStreaming, setIsStreaming] = useRecoilState(isStreamingSelector);

    const handleChange = (event) => {
        setSelected(event.target.value);
    }

    const handleCheckToggle = (sub, key, value) => {
        setHeadsetState(prevDetails => ({
            ...prevDetails,
            [sub]: { ...prevDetails[sub], [key]: !value }
        }));
        console.log(value);
    }

    const handleInputChange = (sub, key, value) => {
        setHeadsetState(prevDetails => ({
            ...prevDetails,
            [sub]: { ...prevDetails[sub], [key]: value }
        }));
        console.log(headsetState)
    };

    const toggleStreamType = () => {
        setHeadsetState(prevDetails => ({
            ...prevDetails,
            enablePowerByBand: !headsetState.enablePowerByBand
        }));
        console.log(headsetState);
    }

    async function enableStream() {
        headsetClient.injectMarker("Starting stream..");
        await headsetClient.start()
        headsetClient.injectMarker("Stream started")
       
        setHeadsetState(prevState => ({
            ...prevState,
            streamStartTime: Math.floor(new Date().getTime() / 1000),
            isStreaming: true
        }));

        // Neurosity Passthrough
        const operators = [
            headsetState.notchFilterSettings.enableNotchFilter ? notchFilter({
                nbChannels: headsetState.notchFilterSettings.nbChannels,
                cutoffFrequency: headsetState.notchFilterSettings.cutoffFrequency,
                bandWidth: headsetState.notchFilterSettings.bandWidth,
                filterHarmonics: headsetState.notchFilterSettings.filterHarmonics,
                samplingRate: headsetState.notchFilterSettings.samplingRate,
                characteristic: headsetState.notchFilterSettings.characteristic,
                order: headsetState.notchFilterSettings.order
            }) : null,
            headsetState.lowPassFilterSettings.enableLowPassFilter ? lowpassFilter({
                nbChannels: headsetState.lowPassFilterSettings.nbChannels,
                cutoffFrequency: headsetState.lowPassFilterSettings.cutoffFrequency,
                samplingRate: headsetState.lowPassFilterSettings.samplingRate,
                characteristic: headsetState.lowPassFilterSettings.characteristic,
                order: headsetState.lowPassFilterSettings.order
            }): null,
            headsetState.highPassFilterSettings.enableHighPassFilter ? highpassFilter({
                nbChannels: headsetState.highPassFilterSettings.nbChannels,
                cutoffFrequency: headsetState.highPassFilterSettings.cutoffFrequency,
                samplingRate: headsetState.highPassFilterSettings.samplingRate,
                characteristic: headsetState.highPassFilterSettings.characteristic,
                order: headsetState.highPassFilterSettings.order
            }): null,
            headsetState.bandPassFilterSettings.enableBandPassFilter ? bandpassFilter({
                nbChannels: headsetState.bandPassFilterSettings.nbChannels,
                cutoffFrequency: [headsetState.bandPassFilterSettings.lowCutOff, headsetState.bandPassFilterSettings.highCutOff],
                samplingRate: headsetState.bandPassFilterSettings.samplingRate,
                characteristic: headsetState.bandPassFilterSettings.characteristic,
                order: headsetState.bandPassFilterSettings.order
            }): null,
            headsetState.settings.enableSliceFFT ? sliceFFT([headsetState.settings.sliceFFTSettings.minFFT, headsetState.settings.sliceFFTSettings.maxFFT]) : null,
            headsetState.settings.enablePowerByBand ? powerByBand(): epoch({
                duration: headsetState.settings.duration,
                interval: headsetState.settings.interval,
                samplingRate: headsetState.settings.srate
            }),
            fft({
                bins: headsetState.settings.bins
            }),
        ].filter(op => op !== null);

        zipSamples(headsetClient.eegReadings).pipe(
            ...operators
        ).subscribe(data => {
            // Update chart function
            //console.log(data)
            setHeadsetState(prevState => ({
                ...prevState,
                headsetStream: [...prevState.headsetStream, data]
            }));
        })

        console.log(operators);
        /*
        headsetClient.telemetryData.subscribe(telemetry => {
            console.log('== Telemetry ==');
            console.log(telemetry);
        });
        headsetClient.accelerometerData.subscribe(acceleration => {
            console.log('== Acceler ==')
            console.log(acceleration);
        });
        */
    }

    async function pauseStream() {
        console.log("Stopping stream..");
        headsetClient.injectMarker("Pausing stream")
        await headsetClient.pause();
        setIsStreaming(false);
    }

    return (
        <>
            <div>
                <p
                    style={{
                        color: "#02111b", 
                        backgroundColor: "#6bf178",
                        textAlign: "center", 
                        margin: "0em",
                        padding: ".5em"}}>
                    Select Stream Method
                </p>
            </div>
            <div
                style={{
                    backgroundColor: "#02111b",
                    }}>
                <div
                    style={{
                        padding: ".5em",
                        backgroundColor: "#6bf178",
                        paddingTop: "0"
                    }}
                >
                    <select 
                        style={{padding: ".5em"}}
                        value={selected}
                        className="button"
                        onChange={handleChange}
                        >
                        <option>Raw EEG Stream</option>
                        <option>Power by Band</option>
                    </select>
                </div>
                <div
                    style={{
                        backgroundColor: "#6bf178",
                        padding: ".5em"
                    }}>
                <div
                    style={{
                        border: "ridge #6bf178 .25em", 
                        padding: ".5em", 
                        backgroundColor: "#02111b",
                        marginBottom: ".5em"
                        }}>
                {
                    selected == "Power by Band" && (
                        <>
                            <p>Enabling Power By Band will restart all of the information collected from the current stream.</p>
                            <p>However, it will also provide the average power and band for each channel connected, giving more direct insight to brain states rather than raw EEG data.</p>
                        </>
                    )
                }
                {
                    selected == "Raw EEG Stream" && (
                        <>
                            <p>Raw EEG is the default streaming method.</p>
                            <p>128 samples, per channel, are received every ~450ms.</p>
                            <p>Each sample includes an origination timestamp.</p>
                            <p>All of this information is available when exporting.</p>
                        </>
                    )
                }
                </div>
                <div>
                    {
                        (selected == "Power by Band" && headsetState.enablePowerByBand) || (selected == "Raw EEG Stream" && !headsetState.enablePowerByBand) ? (
                            <>    
                                {
                                    headsetClient &&
                                    <>
                                        <div className="stream-options">
                                            {<button className="button" style={{ margin: "auto", display: "block" }} onClick={isStreaming ? pauseStream : enableStream}>{isStreaming ? "Pause Streaming" : "Enable Streaming"}</button>}
                                        </div>
                                    </>
                                }                            
                            </>
                        )
                        :
                        <>
                            <button className="button-inverted" style={{width: "100%"}} onClick={toggleStreamType}>Update Stream Method</button>
                        </>
                    }
                </div>
                </div>
            </div>
        </>
    )
}
export default SetupStream
